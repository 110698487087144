import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
// @mui
import { Box, Divider, MenuItem, Portal, Stack, Typography } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../../components/Iconify';
import Avatar from '../../../components/Avatar';
import ConfirmText from '../../../components/ConfirmText';
import Loading from '../../../components/Loading';
import MenuPopover from '../../../components/MenuPopover';
import useBoolean from '../../../hooks/useBoolean';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
import axiosInstance from '../../../utils/axios';
import createAvatar from '../../../utils/createAvatar';
import ExpireTimePopover from './ExpireTimePopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import Image from '../../../components/Image';
import useMailToolFunction from '../../../hooks/useMailToolFunction';

export default function AccountPopover() {
  const { user, logout } = useAuth();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const confirmPopup = useBoolean();

  const loading = useBoolean();

  const isMobile = useResponsive('down', 'lg');

  const { stopQueue } = useMailToolFunction();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    if (!user) {
      loginWithGoogle();
      return;
    }
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const { googleLogin } = useAuth();

  const [setSubmittingGoogle] = useState(false);

  const loginGoogle = async (code) => {
    if (code) {
      console.log('loginGoogle');
      setSubmittingGoogle(true);
      await googleLogin(code);
      setSubmittingGoogle(false);
    }
    return enqueueSnackbar(translate('login.message.failure'), { variant: 'error' });
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (response) => {
      loginGoogle(response?.code);
    },
    onError: (error) => {
      console.error('useGoogleLogin error: ', error);
    },
    useOneTap: true,
    auto_select: true,
    cancel_on_tap_outside: false,
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: window.location.origin,
    state: JSON.stringify({ redirectTo: window.location.href.replace(window.location.origin, '') }),
  });

  const handleLogInOut = () => {
    if (user) {
      // oneTap issue: must use Google Logout
      stopQueue();
      googleLogout();
      logout();
      setOpen(null);
    }
  };

  const handleChangeAPIKey = async (newAPIKey) => {
    try {
      loading.onTrue();

      const {
        data: { message },
      } = await axiosInstance.post('api/v2/tool/openai/', { openai_api_key: newAPIKey });
      enqueueSnackbar(message);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.messages || error?.message || error?.detail || translate('server_error'), {
        variant: 'error',
      });
    } finally {
      confirmPopup.onFalse();
      loading.onFalse();
    }
  };

  const othersContent = isMobile ? (
    <Stack direction="row" flexWrap="wrap" gap={1} p={1}>
      <ExpireTimePopover />
      <LanguagePopover />
      <NotificationsPopover />
    </Stack>
  ) : (
    ''
  );

  const POSITIONS = {
    1: {
      frame: {
        height: 60,
        width: 60,
      },
      avatar: {
        top: 25,
        left: 29,
      },
    },
    2: {
      frame: {
        height: 60,
        width: 60,
      },
      avatar: {
        top: 25.5,
        left: 30.5,
        width: 32,
        height: 32,
      },
    },
    3: {
      frame: {
        height: 60,
        width: 88.2,
      },
      avatar: {
        top: 27,
        left: 44.5,
        width: 32,
        height: 32,
      },
    },
    4: {
      frame: {
        height: 60,
        width: 90,
      },
      avatar: {
        top: 27,
        left: 45.5,
        width: 32,
        height: 32,
      },
    },
    nopay: {
      frame: {
        height: 60,
        width: 60,
      },
      avatar: {
        top: 31,
        left: 29,
      },
    },
  };

  const tierFrame = useMemo(() => {
    const assetFolder = `/assets/smt/tier/`;
    try {
      const tier = user?.mailtool?.tier;
      if (tier) {
        return {
          src: `${assetFolder}${tier}.png`,
          ...POSITIONS[tier],
        };
      }
      return { src: `${assetFolder}nopay.png`, ...POSITIONS.nopay };
    } catch (error) {
      return { src: `${assetFolder}nopay.png`, ...POSITIONS.nopay };
    }
  }, [user]);

  return (
    <>
      <Stack
        onClick={handleOpen}
        sx={{
          p: 0,
          cursor: 'pointer',
          '&:hover': { transform: 'scale(1.01)', transition: 'all 0.1s' },
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Image src={tierFrame?.src} sx={{ position: 'relative', ...tierFrame?.frame, zIndex: 10 }} />
          <Avatar
            alt={user?.name}
            color={createAvatar(user?.name).color}
            sx={{
              position: 'absolute',
              transform: 'translate(-50% ,-50%)',
              height: 30,
              width: 30,
              ...tierFrame?.avatar,
            }}
          >
            {createAvatar(user?.name).name}
          </Avatar>
        </Box>
      </Stack>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.phone}
          </Typography>
        </Box>

        {othersContent}

        {/* <Divider /> */}
        {/* <AffiliateInfo />
        <CopyAffiliateLink sx={{ m: 1 }} /> */}

        <Divider />

        <MenuItem
          onClick={user ? handleLogInOut : loginWithGoogle}
          // loading={isSubmittingGoogle}
          sx={{
            justifyContent: 'start',
            m: 1,
          }}
        >
          <Iconify icon={`ic:baseline-logout`} sx={{ width: 12, height: 12, mr: 1 }} />
          {user ? 'Logout' : 'Login'}
        </MenuItem>
      </MenuPopover>
      <Portal>
        <ConfirmText
          content={translate('tool.popup.openai.title')}
          handleClose={confirmPopup.onFalse}
          isOpen={confirmPopup.value}
          handleConfirm={handleChangeAPIKey}
        />
        <Loading loading={loading.value} />
      </Portal>
    </>
  );
}
