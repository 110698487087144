const en = {
  estimated_time: '{{email}} email in {{time}}',
  btn: {
    save: 'Save',
  },
  recipient: {
    list: 'Email List',
    male: 'Male',
    female: 'Female',
    form: {
      title: 'Data Import',
      name: 'Data name',
      file: {
        heading: 'Select a CSV file',
        title1: 'Contains 3 columns last_name, first_name, email, sex, pronoun',
        example: 'Sample file',
      },
    },
  },
  accounts: {
    title: 'Account',
    add: 'Add Gmail account',
  },
  compose: {
    form: {
      subject: 'Title',
      normal: 'Normal',
      html: 'HTML',
      file: {
        heading: 'Photo/Video/Attachments',
        title1: 'Drag and drop or select files',
      },
    },
    table: {
      name: 'Name',
      sex: 'Gender',
      send: 'Sent',
      open: 'Opened',
      bounce: 'Bounce',
      error: 'Error',
    },
    btn: {
      send: 'Send',
      stop: 'Stop',
      ai_spam_check: `AI SpamCheck: Before {{score}} | After {{rewriteScore}}`,
    },
    tooltip: {
      ai_spam_check: `AI checks content`,
    },
  },
  popup: {
    pricing: {
      title: 'Tools to support sending mail above',
      sub: 'Gmail',
      heading: 'PRICE LIST',
      features: [
        'No need to log in with gmail ID and password 🔒',
        'Save favorite contact groups',
        'Import data from excel',
        'Buy 2 more gmail for only 50k/month',
      ],
      tier: 'Tier',
      tiers: [
        {
          tier: 1,
          name: 'BASIC',
          cost: {
            month: 100000,
            year: 50000,
          },
          features: [
            {
              text: 'Add unlimited emails',
              info: 'Add unlimited emails',
            },
            {
              text: 'Send unlimited emails',
              info: 'Send unlimited emails',
            },
            {
              text: 'Used with 1 gmail',
              info: 'Used with 1 gmail',
            },
          ],
        },
        {
          tier: 2,
          name: 'PLUS',
          cost: {
            month: 200000,
            year: 100000,
          },
          features: [
            {
              text: 'Used with 3 gmail',
              info: 'Used with 3 gmail',
            },
            {
              text: 'AI Rewrite',
              info: 'AI Rewrite',
            },
            {
              text: 'Personalization',
              info: 'Personalize email content',
            },
          ],
        },
        {
          tier: 3,
          name: 'PRO',
          cost: {
            month: 400000,
            year: 200000,
          },
          features: [
            {
              text: 'Schedulable',
              info: 'You can schedule sending email campaigns',
            },
            {
              text: 'Flow automation',
              info: 'Flow automation',
            },
            {
              text: 'Link 10 gmails',
              info: 'Link 10 gmails',
            },
          ],
        },
      ],
      monthly: 'Monthly',
      semi_annual: 'Semi-annual',
      annual: 'Every year',
      month_value: '100K',
      annual_value: '50K',
      discount: 'Save up to {{percent}}%',
      bill_annual: 'Year',
      bill_monthly: 'Month',
    },
  },
  messages: {
    select_recipient: 'Please select at least 1 collection or contact',
    fill_content: 'Please fill in submission content',
    limit_sent: `{{amount}}mail/day limit reached`,
    limit_accounts: `Select maximum {{amount}} accounts`,
    select_account: 'Please select at least 1 gmail account',
    add_account_success: 'Added gmail account successfully',
    add_account_failed: 'Adding gmail account failed',
    remove_account_success: 'Successfully deleted gmail account',
    remove_account_failed: 'Delete gmail account failed',
  },
};

export default en;
