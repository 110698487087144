import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useRef } from 'react';
import { useStatsGuard } from '../../../../../hooks/lead';
import useBoolean from '../../../../../hooks/useBoolean';
import useLocales from '../../../../../hooks/useLocales';
import useMailToolContext from '../../../../../hooks/useMailToolContext';
import useMailToolFunction from '../../../../../hooks/useMailToolFunction';
import useRefCustom from '../../../../../hooks/useRefCustom';
import { delayFunc, generateRandomString, randomInRange, removeEmoji } from '../../../../../utils/others';
import { randomSpinData } from '../../../../../utils/tool/spin';
import {
  createEmailAsset,
  createEmailCampaign,
  findContactEmail,
  findContactInfo,
  getContactsEmail,
  getContactsEmailOfGroupContacts,
} from '../../helper';

const initialState = {
  isRunning: false,
  mailResults: [],
  onCompose: () => {},
  onCancel: () => {},
};

const DEFAULT_PAGINATION = {
  groupContact: null,
  contact: null,
};

const ComposeContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
ComposeProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function ComposeProvider({ children }) {
  const isRunning = useBoolean();

  const { canUseTier } = useStatsGuard();

  const { enqueueSnackbar } = useSnackbar();

  const { translate, currentLang } = useLocales();

  const { translate: tMessages } = useLocales('mailing.messages');

  const { getCurrentEmails, getMailDailyLimit, markAsSent, getMailSentToday } = useMailToolContext();

  const { composeMail, restartSignal, stopQueue } = useMailToolFunction();

  const [, setGroupContactsIds, groupContactsIdsRef] = useRefCustom();

  const [, setDataPagination, dataPaginationRef] = useRefCustom({ ...DEFAULT_PAGINATION });

  const [, setContactsIds, contactsIdsRef] = useRefCustom();

  const [, setComposeData, composeDataRef] = useRefCustom();

  const [, setEmailQueue, emailQueueRef] = useRefCustom([]);

  const [, setSetting, settingRef] = useRefCustom();

  const [mailResults, setMailResults, mailResultsRef] = useRefCustom([]);

  const [, setAsset, assetRef] = useRefCustom();

  const taskRef = useRef();

  const getCurrentEmail = () => {
    const mailSelected = getCurrentEmails();

    const validAccounts = mailSelected?.filter((item) => item?.remains > 0);

    if (validAccounts?.length !== 0) {
      const rad = randomInRange({ from: 0, to: validAccounts?.length - 1 });
      return validAccounts[rad];
    }

    return null;
  };

  const isRunOutOfData = () => {
    const contactsIds = contactsIdsRef?.current;
    const groupContactsIds = groupContactsIdsRef?.current;
    return contactsIds?.length === 0 && groupContactsIds?.length === 0;
  };

  const addToEmailQueue = (emails) => {
    const emailExisted = mailResultsRef?.current?.map((item) => item?.email);
    const current = emailQueueRef?.current;
    const temp = [...current, ...emails]?.filter((email) => emailExisted?.indexOf(email) === -1);
    setEmailQueue(_.uniq(temp));
  };

  const loadMoreData = async () => {
    try {
      const currentPagination = { ...(dataPaginationRef?.current || {}) };
      // Group contact pagination
      const groupContact = currentPagination?.groupContact;
      // Contact pagination
      const contact = currentPagination?.contact;

      // Groups, contacts selected
      const contactsIds = contactsIdsRef?.current;
      const groupContactsIds = groupContactsIdsRef?.current;

      if (contactsIds?.length !== 0) {
        const {
          response: { page, total_pages: total, results },
        } = await getContactsEmail(contactsIds, contact?.page || 1);
        if (results) {
          addToEmailQueue(results);
        }
        if (total && page && page === total) {
          // Set empty -> mark as completed run all data in contacts
          setContactsIds([]);
        } else {
          currentPagination.contact = { page: page + 1 };
        }
      } else if (groupContactsIds?.length !== 0) {
        const {
          response: { page, total_pages: total, results },
        } = await getContactsEmailOfGroupContacts(groupContactsIds, groupContact?.page || 1);
        if (results) {
          addToEmailQueue(results);
        }
        console.log('groupContactsIds', page, total, results);
        if (total && page && page === total) {
          // Set empty -> mark as completed run all data in contact groups
          setGroupContactsIds([]);
        } else {
          currentPagination.groupContact = { page: page + 1 };
        }
      }
      setDataPagination({ ...currentPagination });
      console.log('currentPagination', currentPagination);
    } catch (error) {
      console.log('loadMoreData', error);
    }
  };

  const getRemainsEmail = () => {
    const currentEmails = emailQueueRef?.current;
    if (currentEmails?.length !== 0) {
      const email = currentEmails.shift();
      setEmailQueue([...currentEmails]);
      return email;
    }
    return null;
  };

  const runLoopTask = () => {
    const { delay } = settingRef?.current || {};
    const timeout = randomInRange(delay);

    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }

    taskRef.current = setTimeout(() => {
      loopTask();
    }, timeout * 1000);
  };

  const mappingData = (data, content = '') => {
    let baseText = content || '';

    const sex = data?.meta?.sex || translate('pronoun');
    const pronoun = data?.pronoun || translate('pronoun');
    const lastName = data?.last_name || '';
    const firstName = data?.first_name || '';
    const name = `${lastName} ${firstName}`;

    const SPECIAL_SYNTAX = ['@name', '@sex', '@pronoun', '@first_name', '@last_name'];
    const MAPPING_FIELDS = {
      '@name': name,
      '@sex': sex,
      '@pronoun': pronoun,
      '@first_name': firstName,
      '@last_name': lastName,
    };

    SPECIAL_SYNTAX.forEach((syntaxItem) => {
      const mapped = MAPPING_FIELDS[syntaxItem];
      if (mapped) {
        baseText = baseText.replaceAll(syntaxItem, mapped);
      }
    });
    return baseText;
  };

  const mapSpinData = (spinData) => {
    const { spin, template } = spinData;
    let modifiedContent = template;
    const spinRandom = randomSpinData(spin);
    Object.keys(spinRandom).forEach((key) => {
      modifiedContent = modifiedContent.replace(`{{${key}}}`, spinRandom[key]);
    });
    return modifiedContent;
  };

  const loopTask = async () => {
    const email = getRemainsEmail();

    if (!email) {
      if (isRunOutOfData()) {
        onCancel();
        return;
      }
      await loadMoreData();
    } else {
      // send mail here !!!
      console.log('sending', email);

      const composeData = composeDataRef?.current || {};
      const { attachments, contentType, spinData } = composeData;

      let body = composeData?.body;
      let subject = composeData?.subject;
      const { data } = await findContactInfo(email);
      const contactId = data?.contact_id;

      if (canUseTier(2)) {
        if (spinData) {
          body = mapSpinData(spinData);
        }

        // Get data and mapping
        body = mappingData(data, body);
        subject = mappingData(data, subject);
      }

      // console.log(subject, body);
      const sender = getCurrentEmail();

      const dailyMailLimit = getMailDailyLimit();
      const mailSent = getMailSentToday();
      const exceededDailyMail = mailSent >= dailyMailLimit;
      // console.log('mailSent/dailyMailLimit: ', mailSent, '/', dailyMailLimit);

      if (!sender || exceededDailyMail) {
        enqueueSnackbar(tMessages('limit_sent', { amount: dailyMailLimit }), {
          variant: 'warning',
        });
        onCancel();
        return;
      }

      const res = await composeMail(email, subject, body, attachments, contentType, sender?.index);
      const emailId = res?.result;
      console.log('sent with emailId: ', emailId);
      onResult(sender, email, emailId, contactId);
    }

    runLoopTask();
  };

  const syncCampaign = async (emailId, contactId) => {
    if (emailId && contactId) {
      const asset = assetRef?.current;
      if (asset) {
        createEmailCampaign({ contactId, assetId: asset?.asset_id, emailId });
      }
    }
  };

  const onResult = (sender, email, emailId, contactId) => {
    try {
      const sent = !!emailId;
      const current = [...(mailResultsRef?.current || [])];
      let index = current?.findIndex((item) => item?.email === email);
      if (index === -1) {
        // Add new item
        const newItem = {
          email,
          sent: 0,
        };
        index = current.push(newItem) - 1;
        if (sent) {
          syncCampaign(emailId, contactId);
        }
      }
      const value = sent ? 1 : 0;
      current[index].sent = (current[index].sent || 0) + value;
      setMailResults(current);
      if (sent) {
        markAsSent(sender?.email);
      }
    } catch (error) {
      console.log('onResult', error);
    }
  };

  const createMailAsset = async (data) => {
    try {
      const { subject, html, contactsIds, groupContactsIds, text } = data;
      const shortTitle = removeEmoji(subject).normalize('NFKC').trim();
      const title = `${shortTitle}_${generateRandomString(70 - shortTitle?.length)}`;

      const sender = getCurrentEmail();

      const payload = {
        title: title.substring(0, 66),
        description: text.substring(0, 3000),
        subject,
        sender: sender?.email,
        receiption: groupContactsIds?.join(',') || contactsIds?.length,
        open: true,
        click: true,
        senderType: 'mail',
        content: html,
      };
      const resp = await createEmailAsset(payload);
      return resp?.data;
    } catch (error) {
      console.log('createMailAsset', error);
    }
  };

  const onCompose = async (data) => {
    try {
      const currentActive = getCurrentEmails();
      if (currentActive?.length === 0) {
        enqueueSnackbar(tMessages('select_account'), { variant: 'error' });
        return;
      }

      isRunning.onTrue();
      restartSignal();
      // TODO get unique email domains from contacts (exclude known email provider such as gmail, yahoo, etc.) Then validate MX record of each domain.
      const asset = await createMailAsset(data);
      setAsset(asset);
      const { subject, attachments, delay, body, contentType, contactsIds, groupContactsIds, spinData } = data;
      setComposeData({
        subject,
        attachments,
        body,
        contentType,
        spinData,
      });
      setMailResults([]);
      setContactsIds([...contactsIds]);
      setGroupContactsIds([...groupContactsIds]);
      setSetting({ delay });
      loopTask();
      // TODO once finished, bulk sync email campaign stats: soft bounced, hard bounced, delivered, opened, clicked
    } catch (error) {
      console.log(error);
    }
  };

  const onCancel = () => {
    isRunning.onFalse();
    clearTimeout(taskRef?.current);
    stopQueue();
    setComposeData(null);
    setContactsIds([]);
    setGroupContactsIds([]);
    setEmailQueue([]);
    setSetting(null);
    setDataPagination(DEFAULT_PAGINATION);
  };

  return (
    <ComposeContext.Provider
      value={useMemo(
        () => ({
          isRunning: isRunning?.value,
          mailResults,
          onCompose,
          onCancel,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRunning?.value, mailResults]
      )}
    >
      {children}
    </ComposeContext.Provider>
  );
}

export { ComposeProvider, ComposeContext };
