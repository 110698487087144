import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import useTenant from '../hooks/useTenant';
import { routesToPath } from '../utils/tenantUtils';
import smtRoutes from './smt-route';
import uptinRoutes from './uptin-route';
import tiktokRoutes from './tiktok-route';
import mailRoutes from './mail-route';
import useSettings from '../hooks/useSettings';
import { TIKTOKTOOL_COLOR } from '../theme/palette';

// ----------------------------------------------------------------------

export default function Router({ deactivateRoutes = [] }) {
  // ====================== DETECT SOME TENANT ROUTES =======================
  let NAME = 'uptin';
  const { user } = useAuth();

  let { isSMT, isTTTool, isMailTool } = useTenant();
  if (window.location.origin?.indexOf('localhost') !== -1) {
    isSMT = true;
    isTTTool = false;
    isMailTool = false;
  }

  if (isSMT) {
    NAME = 'fbtool';
  }

  if (isTTTool) {
    NAME = 'tiktoktool';
  }

  if (isMailTool) {
    NAME = 'mailtool';
  }

  const isAdminTenant = user?.package_using?.is_admin || false;

  // Only admin of package
  if (!isAdminTenant) {
    deactivateRoutes.push('/staff/list');
  }
  // ====================== END =======================

  // ====================== Change primary color =======================
  const { changeColor } = useSettings();
  useEffect(() => {
    if (isTTTool) {
      changeColor(TIKTOKTOOL_COLOR);
    }
  }, [isTTTool]);
  // ====================== END =======================

  const ROUTES = {
    tiktoktool: tiktokRoutes,
    fbtool: smtRoutes,
    uptin: uptinRoutes,
    mailtool: mailRoutes,
  };

  const routes = ROUTES[NAME];
  return useRoutes(routesToPath(routes, deactivateRoutes));
}
