const en = {
  info: {
    following: 'Following',
    followers: 'Followers',
    like: 'Like',
  },
  interact: {
    search_place: 'Search at',
    setting: {
      keyword: 'Keyword',
      comment: 'Comment',
      heart: 'Like',
      view_profile: 'View profile',
      follow: 'Follow',
      save: 'Save',
      inbox: 'Inbox',
      repost: 'Repost',
    },
    types: {
      following: 'Followed',
      follower: 'Follower',
      friend: 'Friend',
      keyword: 'Keyword',
      recommend: 'Recommended',
    },
    form: {
      comment_amount: 'Posts amount',
      like_amount: 'Posts amount',
      amount: 'Amount',
      clip_amount: 'Video amount',
      action_amount: 'Action amount',
      keyword: 'Keyword',
      channel: 'Channels (Upto {{amount}})',
      errors: {
        maximum: 'Maximum {{amount}}',
        minimum: 'Minimum {{amount}}',
        required: 'This is required field',
      },
    },
  },
  lead: {
    categorize: {
      title: 'Categorize',
      seller: 'Seller',
      buyer: 'Buyer',
      unknown: 'Unknown',
    },
    action: {
      title: 'Action',
      follow: 'Follow (Beta)',
      inbox: 'Messaging (Beta)',
      comment: 'Comment',
      heart: 'Like',
    },
    table: {
      head: {
        post: 'Content',
        name: `Poster's name`,
        uid: 'UID',
        comment: 'Comment',
        follow: 'Follow',
        type: 'Type',
        inbox: 'Messaging',
        like: 'Like',
        categorize: 'Categorize',
      },
    },
  },
  stats: {
    days: '{{days}} days',
    overview: {
      title: 'Overview',
      video: `{{video}} Video Views`,
      profileView: `{{profileView}} Profile Views`,
      like: `{{like}} Likes`,
      comment: `{{comment}} Number of comments`,
      share: `{{share}} Share`,
    },
    viewer: {
      title: 'Viewer',
      total: `{{total}} Total Viewers`,
      male: `{{male}} Male`,
      female: `{{female}} Female`,
      age: `{{age_percent}} Age from {{age}}`,
      country_percent: `{{country_percent}} {{country}}`,
    },
    follower: {
      title: 'Followers',
      total: `{{total}} Total followers`,
      male: `{{male}} Male`,
      female: `{{female}} Female`,
      age: `{{age_percent}} Age from {{age}}`,
      country_percent: `{{country_percent}} {{country}}`,
    },
    traffic: {
      title: 'Traffic source',
      trend: 'Trend',
      profile: 'Personal profile',
      search: 'Search',
      follower: 'Following',
      music: 'Music',
    },
  },
  messages: {
    fill_keyword: 'Please enter the keyword',
  },
  export: {
    comment: 'Comment',
    heart: 'Like',
    view_profile: 'View profile',
    save: 'Save',
    repost: 'Repost',
    follow: 'Follow',
    type: 'Type',
    success: 'Success',
    fail: 'Fail',
    post: 'Post',
    post_uid: 'post UID',
    status: 'Status',
    actor: 'Poster',
    actor_uid: 'Poster UID',
    categorize: 'Categorize',
    inbox: 'Messaging',
    like: 'Like',
    name: 'Name',
    source: {
      comment: 'Comment',
      post: 'Post',
      share: 'Sharer',
      commenter: 'Commenter',
      reactor: 'Interacter',
    },
  },
  popup: {
    pricing: {
      title: 'Tools to support interaction on ',
      sub: 'Tiktok',
      heading: 'PRICE LIST',
      features: [
        'No need to log in with Tiktok ID and password 🔒',
        'AI rewrites content for free',
        'Save favorite group',
      ],
      tier: 'Tier',
      tiers: [
        {
          tier: 1,
          name: 'BASIC',
          cost: {
            months: 100000,
            year: 50000,
          },
          features: [
            {
              text: 'Comment',
              info: 'Comment',
            },
            {
              text: 'AI spin',
              info: 'Every time you post, the tool automatically uses AI to rewrite the content to avoid Spam.',
            },
            {
              text: 'Heart drop',
              info: 'Heart drop',
            },
            {
              text: 'Repost',
              info: 'Repost',
            },
            {
              text: 'Save favorite user groups',
              info: 'Save favorite user groups',
            },
            {
              text: 'Follow',
              info: 'Follow',
            },
            {
              text: 'Save video',
              info: 'Save',
            },
            {
              text: 'View profile',
              info: 'View profile',
            },
          ],
        },
        {
          tier: 2,
          name: 'PLUS',
          cost: {
            months: 200000,
            year: 100000,
          },
          features: [
            {
              text: 'Search for customers',
              info: 'AI searches for customers',
            },
            {
              text: 'Search in ads, saved link',
              info: 'Search in ads, saved links',
            },
            {
              text: 'Send message',
              info: 'Send message',
            },
            {
              text: 'Automatically select products/services',
              info: 'Automatically select the most suitable product/service',
            },
          ],
        },
      ],
      monthly: 'Monthly',
      semi_annual: 'Semi-annual',
      annual: 'Every year',
      month_value: '100K',
      annual_value: '50K',
      discount: 'Save up to {{percent}}%',
      bill_annual: 'Year',
      bill_monthly: 'Month',
    },
  },
};

export default en;
