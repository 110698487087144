import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useGroupStats } from '../hooks/tool-utils';
import useAuth from '../hooks/useAuth';
import useBoolean from '../hooks/useBoolean';

// hooks
import useRefCustom from '../hooks/useRefCustom';
import useToolFunction from '../hooks/useToolFunction';
import { EmitterService } from '../utils/event';
import { fDateTime } from '../utils/formatTime';
import { parseJSON, splitJSON } from '../utils/others';
import { ExtractResponse } from '../utils/tool';
import { saveLink } from '../utils/tool/api';

// utils
import axios from '../utils/axios';
import { useStatsGuard } from '../hooks/lead';

const VIDEO_UPLOAD_CONFIGS = 'video-upload-configs';

const initialState = {
  FBUser: {},
  userFriends: [],
  pagesLikedFollowed: [],
  groupsData: [],
  formatPresets: [],
  // Video
  videoConfigs: {},
  // Main funcs
  checkExtensionActive: () => {},
  getExtensionVersion: () => {},
  postToGroupsWithMultiContent: () => {},
  postSeedingComments: () => {},
  stopQueue: () => {},
  getSearchPosts: () => {},
  leadsSeeding: () => {},
  onLoadFriends: () => {},
  getMentionSource: () => {},
  emitterNoty: { on: () => {}, off: () => {}, send: () => {} },
  ACTIONS_DELAY: {},
};

const responseHelper = new ExtractResponse();

const FBContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
FBProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function FBProvider({ children }) {
  const {
    getFriends,
    getGroupsJoined,
    getProfile,
    updateFBData,
    getTextFormatPresets,
    checkExtensionActive,
    getExtensionVersion,
    postToGroupsWithMultiContent,
    postSeedingComments,
    stopQueue,
    getSearchPosts,
    leadsSeeding,
    getPageLikedOrFollowed,
    readDataWithUrl,
    getShareLinkOfPost,
    getPageInfo,
    getFBLocale,
    ACTIONS_DELAY,
    // getRepliesFromComment,
  } = useToolFunction(
    () => {},
    () => {}
  );

  const { asyncGetGroupsData, saveGroupStats, getGroupStats, onLoadPreviousData } = useGroupStats();

  const { onLoadFBUID } = useStatsGuard();

  const { user: userAuth, loadFbtoolAdCategories } = useAuth();

  const [user, setUserRef, userRef] = useRefCustom(null);

  useEffect(() => {
    setUserRef(userAuth);
  }, [userAuth]);

  // Facebook data
  const [FBUser, setFBUser, FBUserRef] = useRefCustom(null);

  const [groupsData, setGroupsData, groupsDataRef] = useRefCustom([]);

  const [formatPresets, setFormatPresets, formatPresetsRef] = useRefCustom([]);

  const [videoConfigs, setVideoConfigs] = useState(null);

  const [userFriends, setUserFriends, userFriendsRef] = useRefCustom([]);

  const [pagesLikedFollowed, setPagesLikedFollowed, pagesLikedFollowedRef] = useRefCustom([]);

  const { enqueueSnackbar } = useSnackbar();

  // -------------------------------------------------------------------------
  const [emitterNoty, setEmitterNoty] = useState(new EmitterService());

  const validateResp = (resp) => {
    try {
      // The data schould be contains content
      return resp?.actor && resp?.content?.message?.text && resp?.feedback?.id;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const handleMessage = async (event) => {
      const { payload, type } = event?.data || {};
      const link = payload?.data?.link;
      if (type === 'R_SAVE_LINK' && link) {
        const regex = /^https:\/\/www\.facebook\.com\/share\/[a-z]\/[a-zA-Z0-9]+\/$/;
        if (regex.test(link)) {
          console.log('Use just copy facebook link: ', link);
          const resp = await readDataWithUrl(link);
          const isValid = validateResp(resp);
          if (isValid) {
            const { success, message } = await saveLink(link, resp);
            if (success) {
              emitterNoty.send('link-saved');
            } else if (message) {
              enqueueSnackbar(message, { variant: 'error' });
            }
          }
        }
      }

      if (type === 'R_ADS_DATA') {
        checkAdsData(payload?.data);
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const checkAdsData = async (data) => {
    try {
      const responses = data?.split(/}\s*{\s*/);

      let results = [];

      for (const [index, resp] of responses?.entries()) {
        try {
          let temp = responses?.length > 1 ? `${resp}}` : resp;
          if (index !== 0) {
            temp = `{${resp}}`;
          }
          // Last index
          if (responses?.length > 1 && responses?.length - 1 === index) {
            temp = `{${resp}`;
          }
          const parsed = splitJSON(temp);
          const formated = responseHelper.extractAdsPostsLink(parsed);
          if (formated && formated?.length !== 0) {
            results = _.uniqBy([...results, ...formated], 'link');
          }
        } catch (error) {
          console.log('parse JSON error: ', error);
        }
      }
      let promises = results?.map(async (item) => ({ link: await getShareLinkOfPost(item?.url), ...item }));
      const listUri = await Promise.all(promises);

      promises = listUri?.map(async (item) => {
        const regex = /^https:\/\/www\.facebook\.com\/share\/[a-z]\/[a-zA-Z0-9]+\/$/;
        if (item?.link && regex.test(item?.link)) {
          const resp = await readDataWithUrl(item?.link);
          const isValid = validateResp(resp);
          if (isValid) {
            if (item?.createdAt) {
              resp.createdAt = fDateTime(item?.createdAt, 'yyyy-MM-dd HH:mm:ss');
            }
            // Category
            if (item?.pageId) {
              const pageInfo = await getPageInfo(item?.pageId);
              console.log('pageInfo', pageInfo);
              if (pageInfo?.categories) {
                resp.categories = pageInfo?.categories;
              }
              if (pageInfo?.category_text) {
                resp.category_text = pageInfo?.category_text;
              }
              if (item?.ad?.id) {
                resp.ad = item?.ad;
              }
            }

            const FBlocale = getFBLocale();
            if (FBlocale) {
              resp.locale = FBlocale;
            }

            const { success, data } = await saveLink(item?.link, resp, true);
            if (success) {
              return data;
            }
          }
        }
        return null;
      });
      const successList = (await Promise.all(promises)).filter((item) => item);
      if (successList?.length !== 0) {
        loadFbtoolAdCategories();
        // emitterNoty.send('ads-saved');
      }
    } catch (error) {
      console.log(error);
    }
  };

  //  ---------------------------------------------------------------------

  useEffect(() => {
    onLoadFriends();
    onLoadPagesLikeFollowed();
  }, [FBUser]);

  const getMentionSource = () =>
    [...groupsDataRef?.current, ...userFriendsRef?.current]?.map((item) => ({ id: item?.uid, name: item?.name }));

  const loadedUseProfile = () => {
    const profile = FBUserRef?.current;
    return profile && Object.prototype.hasOwnProperty.call(profile, 'isPageProfile') && !profile?.isPageProfile;
  };

  // ===========================================

  const isLoadingFriends = useBoolean();
  const onLoadFriends = useCallback(async () => {
    try {
      const friends = userFriendsRef?.current;
      if (!isLoadingFriends?.value && friends?.length === 0 && loadedUseProfile()) {
        isLoadingFriends.onTrue();
        const res = await getFriends();
        setUserFriends(res || []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      isLoadingFriends.onFalse();
    }
  }, [isLoadingFriends?.value]);

  // ===========================================

  const isLoadingpages = useBoolean();
  const onLoadPagesLikeFollowed = async () => {
    try {
      const pages = pagesLikedFollowedRef?.current;
      if (!isLoadingpages?.value && pages?.length === 0 && loadedUseProfile()) {
        isLoadingpages.onTrue();
        const pages = await getPageLikedOrFollowed();
        setPagesLikedFollowed(pages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      isLoadingpages.onFalse();
    }
  };

  // ===========================================

  // Funcs get data of Facebook
  const onGetProfile = async () => {
    const profile = await getProfile();
    setFBUser(profile);
    onLoadFBUID(profile?.uid);
  };

  const callbackData = (groupId, data) => {
    const temp = [...(groupsDataRef?.current || [])];
    const index = temp?.findIndex((item) => item?.uid === groupId);
    if (index !== -1) {
      const value = { ...temp[index], ...(data || {}) };
      temp[index] = value;
      emitterNoty.send('group-data', { groupId, value });
    }
    setGroupsData(temp);
  };

  const onGetGroupsJoined = async () => {
    if (groupsDataRef?.current?.length !== 0) {
      return;
    }
    const groupsJoined = await getGroupsJoined();
    if (groupsJoined) {
      await onLoadPreviousData(groupsJoined);
      const groupStats = getGroupStats();
      let temp = [...groupsJoined];
      const groupsNeedCrawl = [];

      temp = temp?.map((item) => {
        const data = groupStats[item?.uid];
        if (!data) {
          groupsNeedCrawl.push(item?.uid);
        }
        return { ...item, ...(data || {}) };
      });
      setGroupsData(temp);
      // copy groupsNeedCrawl
      const groupsNeedCrawlCopy = [...groupsNeedCrawl];

      // Async crawl
      if (groupsNeedCrawl?.length !== 0) {
        await asyncGetGroupsData(groupsJoined, groupsNeedCrawl, callbackData);
      }

      // console.log('groupsNeedCrawlCopy', groupsNeedCrawlCopy);
      saveGroupStats(groupsJoined, groupsNeedCrawlCopy);
    }
  };

  const onGetTextFormatPresets = async () => {
    const formatPresetsData = await getTextFormatPresets();
    setFormatPresets(formatPresetsData);
  };

  useEffect(() => {
    (async () => {
      const fbInfo = localStorage.getItem('fb_info');
      if (fbInfo) {
        try {
          const data = JSON.parse(fbInfo);
          if (data) {
            await updateFBData(data);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        await updateFBData();
      }
      await onGetProfile();
      if (groupsDataRef?.current?.length === 0) {
        await onGetGroupsJoined();
      }
      if (formatPresetsRef?.current?.length === 0) {
        await onGetTextFormatPresets();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //  ---------------------------------------------------------------------

  return (
    <FBContext.Provider
      value={useMemo(
        () => ({
          FBUser,
          userFriends,
          pagesLikedFollowed,
          groupsData,
          formatPresets,
          // Video
          videoConfigs,
          // Main funcs
          checkExtensionActive,
          getExtensionVersion,
          postToGroupsWithMultiContent,
          postSeedingComments,
          stopQueue,
          getSearchPosts,
          leadsSeeding,
          onLoadFriends,
          getMentionSource,
          ACTIONS_DELAY,
          emitterNoty,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [FBUser, groupsData, formatPresets, videoConfigs, ACTIONS_DELAY, userFriends, pagesLikedFollowed, emitterNoty]
      )}
    >
      {children}
    </FBContext.Provider>
  );
}

export { FBProvider, FBContext };
