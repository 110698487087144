/**
 * Note: Only use this hook inside Websocket Provider
 */
function useListenWS() {
  /**
   * Func listen response from websocket one time
   * @param {string} feature
   * @return {Promise}
   */
  const listenOneTimeData = (wsClient, feature, timeout = null) => {
    return new Promise((resolve, reject) => {
      let timer = null;

      if (timeout && typeof timeout === 'number') {
        timer = setTimeout(() => {
          reject(new Error('Got timeout!'));
        }, timeout * 1000);
      }
      const receiveMessage = (message) => {
        console.log('listenOneTimeData: ', message);
        wsClient?.off(feature, receiveMessage);
        // Clear timeout
        if (timer) {
          clearTimeout(timer);
        }
        resolve(message);
      };
      try {
        wsClient?.on(feature, receiveMessage);
      } catch (error) {
        wsClient?.off(feature, receiveMessage);
        // Clear timeout
        if (timer) {
          clearTimeout(timer);
        }
        reject(new Error('Something break in listen progress!'));
      }
    });
  };

  return { listenOneTimeData };
}

export default useListenWS;
