import _ from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import useListenWS from '../../../../../hooks/useListenWS';
import useRefCustom from '../../../../../hooks/useRefCustom';
import useTTContext from '../../../../../hooks/useTTContext';
import useTTToolFunction from '../../../../../hooks/useTTToolFunction';
import useWebSocket from '../../../../../hooks/useWS';
import axiosInstance from '../../../../../utils/axios';
import { delayFunc, randomInRange } from '../../../../../utils/others';
import { getSavedLinkById } from '../../../../../utils/tool/api';

// ========================================================
const ACTIONS = ['inbox', 'follow', 'comment', 'like'];

const AMOUNT_CATEGORIZE = 10;

const DEFAULT_SETTING = {
  contents: [],
  options: {
    actions: [],
    categorize: [],
  },
};

const TOOL_AI_CATEGORIZE_ENDPOINT = 'api/v2/tool/lead/categorize/';

const CATEGORIES = {
  BUYER: 'buyer',
  SELLER: 'seller',
  UNKNOWN: 'unknown',
};

const DELAY_TIME_EXCEED_LIMIT = process.env.ENVIRONMENT === 'production' ? 5 * 60 * 1000 : 30 * 1000;

const MAX_CHARACTERS = 500;

const AMOUNT_LEAD_PER_RUN = 5;

const DEFAULT_TIME_GAP = 15 * 1000;

const COMMENTS_AMOUNT = 15;

const useTiktokLead = (onReset) => {
  const { getCommentsOfVideo, leadsSeedingSavedLink } = useTTToolFunction();

  const { TTUser } = useTTContext();

  const { checkActiveOfWS, wsClient } = useWebSocket();

  const { listenOneTimeData } = useListenWS();

  const [, setSavedLinkSelected, savedLinkedSelectRef] = useRefCustom([]);

  const [, setSettings, settingsRef] = useRefCustom({ ...DEFAULT_SETTING });

  const [, setLeadsWaitingCategorize, leadsWaitingCategorizeRef] = useRefCustom([]);

  const [leadsReadyForUse, setLeadsReadyForUse, leadsReadyForUseRef] = useRefCustom([]);

  const [leadResults, setLeadResults, leadResultsRef] = useRefCustom([]);

  const taskRef = useRef();

  // ========================= Categorize receive ===================================

  // =======================================================================

  const getRemainsLinkId = () => {
    // Random linkId in comments list waiting for categorize
    const previousComments = _.uniqBy([...leadsWaitingCategorizeRef?.current], 'linkId');
    if (previousComments?.length !== 0) {
      const index = randomInRange({ from: 0, to: previousComments?.length - 1 });
      return previousComments?.[index]?.linkId;
    }

    const current = [...(savedLinkedSelectRef?.current || [])];
    if (current?.length !== 0) {
      const linkId = current?.shift();
      setSavedLinkSelected([...current]);
      return linkId;
    }
    return null;
  };

  const categorizeContents = async (contents, products) => {
    try {
      const { data } = await axiosInstance.post(TOOL_AI_CATEGORIZE_ENDPOINT, {
        data: contents,
        keyword: '',
        products,
      });
      return { success: !!data, data };
    } catch (error) {
      console.log('categorizeContents: ', error);
      return { success: false };
    }
  };

  // =========================================================
  const wsRef = useRef();
  const [, setTTUser, TTUserRef] = useRefCustom(null);
  useEffect(() => {
    wsRef.current = wsClient;
  }, [wsClient]);

  useEffect(() => {
    setTTUser(TTUser);
  }, [TTUser]);

  // =========================================================
  const categorizeLeads = useCallback(
    async (linkInstance, comments) => {
      try {
        const {
          desc,
          videoId,
          author: { uniqueId },
          id,
        } = linkInstance;

        const { contents: products } = settingsRef?.current || {};

        // ONLY GET ABOUT 10 COMMENTS TO CATEGORIZE
        const commentsRemains = [...comments];
        const commentsAfterSpliced = commentsRemains?.splice(0, AMOUNT_CATEGORIZE);

        setLeadsWaitingCategorize(
          _.uniqBy(
            [
              ...leadsWaitingCategorizeRef?.current,
              ...commentsRemains?.map((item) => ({ ...item, linkId: id, filterId: `${item?.uniqueId}_${item?.cid}` })),
            ],
            'filterId'
          )
        );

        const contents = [
          {
            uid: `${uniqueId}_${videoId}`,
            content: desc?.slice(0, MAX_CHARACTERS),
            comments: commentsAfterSpliced?.map((item) => ({
              uid: `${item?.uniqueId}_${item?.cid}`,
              content: item?.text,
            })),
          },
        ];

        await checkActiveOfWS();
        // Categorize
        const { success, data } = await categorizeContents(
          contents,
          products?.map((item) => item?.content)
        );

        if (success && data) {
          const listen = await listenOneTimeData(wsRef?.current, 'fbtool-lead-categorize');

          const results = listen?.data?.results || [];

          return commentsAfterSpliced?.map((item) => {
            const uid = `${item?.uniqueId}_${item?.cid}`;
            const target = results?.find((item) => item?.uid === uid);
            return {
              source: 'comment',
              type: target?.target_type || CATEGORIES.UNKNOWN,
              uid,
              content: item?.text,
              author: {
                ...item,
              },
              parent: {
                content: desc,
                uniqueId,
                keyword: '',
                videoId,
              },
            };
          });
        }
        return [];
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    [wsClient]
  );

  const reformatLeads = (leads) => {
    try {
      const { uid } = TTUserRef?.current || {};
      return leads?.filter((item) => item?.author?.uniqueId !== uid);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getCommentsExistedOfLink = (linkId) => {
    const matchedList = [...leadsWaitingCategorizeRef?.current]?.filter((item) => item?.linkId === linkId);

    // Remove
    setLeadsWaitingCategorize([...leadsWaitingCategorizeRef?.current]?.filter((item) => item?.linkId !== linkId));

    return matchedList;
  };

  const getLeadsFromLinkId = async (linkId) => {
    try {
      if (!linkId) {
        return [];
      }

      const linkInstance = await getSavedLinkById(linkId);
      if (linkInstance) {
        // Get comments
        const {
          videoId,
          author: { uniqueId },
        } = linkInstance;

        let comments = [];
        const previousComments = getCommentsExistedOfLink(linkId);
        if (previousComments?.length !== 0) {
          console.log('Found previous comments');
          comments = [...previousComments];
        } else {
          const { actionAmount } = settingsRef?.current || {};
          comments = await getCommentsOfVideo(uniqueId, videoId, actionAmount || COMMENTS_AMOUNT);
          console.log('New comments: ', comments);
        }

        const categorized = await categorizeLeads(linkInstance, comments);
        // Format to one structure to manage
        return reformatLeads(categorized);
      }
      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const onLoadMoreData = async () => {
    try {
      const linkId = getRemainsLinkId();
      console.log('linkId: ', linkId);
      if (!linkId) {
        onReset();
        return true;
      }

      const leads = await getLeadsFromLinkId(linkId);
      console.log('leads', leads);
      setLeadsReadyForUse([...leadsReadyForUseRef?.current, ...leads]);
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getAmountLeadsPerRun = () => {
    const { actionAmount } = settingsRef?.current;
    if (actionAmount) {
      return Math.min(actionAmount, AMOUNT_LEAD_PER_RUN);
    }
    return AMOUNT_LEAD_PER_RUN;
  };

  const getRemainsLeads = (splice = false) => {
    const {
      options: { categorize },
    } = settingsRef?.current;

    const newLeads = [...(leadsReadyForUseRef?.current || [])];
    const oldLeads = [...(leadResultsRef?.current || [])];
    const highPriorityLeads = oldLeads?.filter((item) => ACTIONS?.every((resultKey) => !!item[resultKey]));

    const amountExpected = getAmountLeadsPerRun();

    if (splice) {
      const sliced = highPriorityLeads?.slice(0, amountExpected);
      const missingAmount = amountExpected - sliced?.length;

      // Matching with categorize selected
      let extraLeads = newLeads?.filter((item) => categorize?.indexOf(item?.type) !== -1);
      extraLeads = extraLeads?.splice(0, Math.max(0, missingAmount));

      // Exclude
      const excludeUID = extraLeads?.map((item) => item?.uid);
      setLeadResults([...oldLeads, ...extraLeads]);
      setLeadsReadyForUse([...newLeads]?.filter((item) => excludeUID?.indexOf(item?.uid) === -1));
      return [...sliced, ...extraLeads];
    }
    return [...newLeads, ...highPriorityLeads];
  };

  // ===========================================================================================

  const getExcludesData = () => {
    return (leadResultsRef?.current || [])
      ?.filter((item) => ACTIONS?.some((actionKey) => item[actionKey]))
      ?.reduce(
        (res, item) => {
          Object.keys(res)?.forEach((key) => {
            if (item[key]) {
              if (key === 'comment') {
                res[key].push(item?.parent?.videoId);
              } else if (key === 'like') {
                const val = `${item?.parent?.uniqueId}_${item?.parent?.videoId}`;
                res[key].push(val);
              } else {
                res[key].push(item?.uid);
              }
            }
          });
          return res;
        },
        { follow: [], inbox: [], comment: [], like: [] }
      );
  };

  const receiveLeadsSeedingCallback = (callbackResult) => {
    console.log('receiveLeadsSeedingCallback: ', callbackResult);

    // {
    //   "uid": "duong_oc__7431459693295551252",
    //   "result": {
    //       "follow": {
    //           "success": true
    //       }
    //   }
    // }

    const { uid, result } = callbackResult || {};

    const newArray = [...leadResultsRef?.current];
    const index = newArray.findIndex((item) => item?.uid === uid);
    if (index !== -1) {
      newArray[index] = { ...newArray[index], ...(result || {}) };
    }
    setLeadResults([...newArray]);
  };

  const gotExceedLimitAI = useRef(false);
  const [isExecutingLeadsSeeding, setIsExecutingLeadsSeeding, isExecutingLeadsSeedingRef] = useRefCustom(false);

  const markAsExceededLimit = () => {
    gotExceedLimitAI.current = true;
  };

  const markAsRunning = () => {
    setIsExecutingLeadsSeeding(true);
  };

  const onRun = async () => {
    try {
      const remains = getRemainsLeads(true);

      const setting = settingsRef?.current;
      const { contents, actionAmount } = setting || {};
      const { actions } = setting?.options || {};

      const { isOperationCanceled } =
        (await leadsSeedingSavedLink(
          {
            contents: contents?.map((item) => ({ ...item, description: item?.content })),
            leads: remains?.map((item) => ({
              uid: item?.uid,
              uniqueId: item?.author?.uniqueId,
              authorId: item?.author?.uid,
              content: item?.content,
              parent: item?.parent,
            })),
            actions,
            excludesData: getExcludesData(),
            actionAmount,
          },
          receiveLeadsSeedingCallback,
          markAsExceededLimit,
          markAsRunning
        )) || {};

      setIsExecutingLeadsSeeding(false);

      if (isOperationCanceled) {
        console.log('Break cycle!! STOPPED.');
        onReset();
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return true;
    }
  };

  const loopTask = async () => {
    // Detect limit AI requests
    if (gotExceedLimitAI?.current) {
      console.log(new Date(), `Got exceeded AI limit. Continue after delay ${DELAY_TIME_EXCEED_LIMIT / 1000} seconds`);
      await delayFunc(DELAY_TIME_EXCEED_LIMIT);
    }

    // Check remains is enough to run?
    const remains = getRemainsLeads();
    const amountExpected = getAmountLeadsPerRun();
    if (remains?.length >= amountExpected) {
      // Run
      console.log('Run');
      const stop = await onRun();
      if (stop) {
        return;
      }
    } else {
      // Get more data
      const stop = await onLoadMoreData();
      if (stop) {
        return;
      }
    }

    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }

    taskRef.current = setTimeout(() => {
      loopTask();
    }, DEFAULT_TIME_GAP);
  };

  // =======================================================================

  const onResetLead = () => {
    setLeadsWaitingCategorize([]);
    setLeadsReadyForUse([]);
    setSavedLinkSelected([]);
    setSettings({ ...DEFAULT_SETTING });
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }
  };

  const onStart = useCallback((data) => {
    try {
      onResetLead();
      setLeadResults([]);
      const { contents, options, savedLinkIds, actionAmount } = data;
      setSavedLinkSelected([...savedLinkIds]);
      setSettings({ contents, options, actionAmount });
      loopTask();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { onStart, onResetLead, leadResults };
};

export default useTiktokLead;
