import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useSettings from './useSettings';
// config
import { allLangs, defaultLang } from '../config';

// ----------------------------------------------------------------------

export default function useLocales(namespace) {
  const { i18n, t: translate } = useTranslation();

  const { onChangeDirectionByLang } = useSettings();

  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
  };

  const mapLocales = useCallback(
    (data) => {
      try {
        const lang = currentLang?.value || 'vn';
        const MAPING_DATA = {
          vn: 'vi_VN',
          en: 'en_US',
        };
        return data?.[MAPING_DATA[lang]];
      } catch (error) {
        return '';
      }
    },
    [currentLang]
  );

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text, options) => (namespace ? translate(`${namespace}.${text}`, options) : translate(text, options)),
    currentLang,
    allLangs,
    mapLocales,
  };
}
