import { useCallback } from 'react';
import useToolFunction from '../../../../../hooks/useToolFunction';
import { delayFunc, randomInRange } from '../../../../../utils/others';

const CANCEL_MSG = `Canceling tasks!`;
const ABORT_MSG = 'Operation canceled';

export default function useRemovePostedPosts() {
  const { getSignal, getPostsPostedOfUserInGroup, stopQueue, removePostedInGroup, restartSignal } = useToolFunction();

  const onRemovePosts = useCallback(async (data, callback, onFinish) => {
    try {
      const { groupIds, delay } = data;

      restartSignal();
      for (const groupId of groupIds) {
        // Fetch data(amount per fetch should be a number divisible for 3. Reason is facebook paignation is 3)
        let data = await getPostsPostedOfUserInGroup(groupId, null, 3);
        if (data) {
          let posts = data?.posts || [];
          while (posts?.length !== 0) {
            const storyIds = posts?.map((item) => item?.storyId);
            for (const storyId of storyIds) {
              const success = await removePostedInGroup(storyId);
              if (callback && success) {
                callback({
                  groupId,
                  amount: 1,
                });
              }
            }
            const pagination = data?.pagination;
            const nextCursor = pagination?.end_cursor;
            const haveNextPage = pagination?.has_next_page;
            if (nextCursor && haveNextPage) {
              data = await getPostsPostedOfUserInGroup(groupId, null, 3, nextCursor);
              posts = data?.posts || [];

              const delayTime = randomInRange(delay) * 1000;
              await delayFunc(delayTime || 3000, getSignal());
            } else {
              posts = [];
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
        return { isOperationCanceled: true };
      }
      return {};
    } finally {
      onFinish();
    }
  }, []);
  return {
    onRemovePosts,
  };
}
