import axios from '../../../utils/axios';

const BASE_URL = `api/v2/tool/email`;

const PAGE_SIZE_CONVERT_IDS_TO_EMAIL = 10;

// API EMAIL ENDPOINTS
export const EMAIL_TOOL_API_ENDPOINTS = {
  ai: {
    check_content: `${BASE_URL}/campaign/content/check/`,
  },
  campaign: {
    create: `${BASE_URL}/campaign/create/`,
    sync: `${BASE_URL}/campaign/stats/sync/`,
    create_asset: 'api/v1/assets/create/',
    list: 'api/v1/assets/',
    edit: (uuid) => `api/v1/assets/${uuid}/`,
  },
  group: {
    list: `${BASE_URL}/contact/groups/`,
    add: `${BASE_URL}/contact/groups/`, // Payload email, group (id)
    delete: `${BASE_URL}/contact/groups/`, // Payload name (group name)
  },
  contact: {
    stats: `${BASE_URL}/stats/`,
    detail: `${BASE_URL}/contact/detail/`,
    info: `${BASE_URL}/contact/info/`,
    read: `${BASE_URL}/contact/read/`,
    bulk_create: `${BASE_URL}/contact/bulk_create/`,
    list: `${BASE_URL}/contact/list/`,
    add: `${BASE_URL}/contact/`, // Payload email, group ( group id), first_name, last_name
    delete: `${BASE_URL}/contact/`, // Payload email, group ( group id)
  },
  template: {
    list: `${BASE_URL}/template/`,
    add: `${BASE_URL}/template/`, // Payload subject, body
    edit: (id) => `${BASE_URL}/template/${id}/`, // Payload subject, body
    detail: (id) => `${BASE_URL}/template/${id}/`,
  },
  accounts: {
    list: `${BASE_URL}/accounts/`,
    add: `${BASE_URL}/accounts/`,
    remove: `${BASE_URL}/accounts/remove/`,
  },
};

export const getGmailAccounts = async () => {
  try {
    const {
      data: { results, count, allIds },
    } = await axios.get(EMAIL_TOOL_API_ENDPOINTS.accounts.list);
    return { results, count, allIds };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const addGmailAccount = async (email) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.accounts.add, { email });
    return { success: true, msg: res.data.messages, account: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const removeGmailAccount = async (email) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.accounts.remove, { email });
    return { success: true, msg: res.data.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const createTemplate = async (subject, html, design) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.template.add, { subject, body: html, meta: { design } });
    return { success: true, msg: res.data.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const getDetailTemplate = async (id) => {
  try {
    const res = await axios.get(EMAIL_TOOL_API_ENDPOINTS.template.detail(id));
    return { success: true, msg: res.data.messages, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const updateTemplate = async (id, subject, html, design) => {
  try {
    const res = await axios.put(EMAIL_TOOL_API_ENDPOINTS.template.edit(id), {
      id,
      subject,
      body: html,
      meta: { design },
    });
    return { success: true, msg: res.data.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const deleteTemplate = async (subject) => {
  try {
    const res = await axios.delete(`${EMAIL_TOOL_API_ENDPOINTS.template.add}?subject=${subject}`);
    return { success: true, msg: res?.data?.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const createGroup = async (groupName) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.group.add, { name: groupName });
    return { success: true, msg: res?.data?.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const deleteGroup = async (groupName) => {
  try {
    const res = await axios.delete(`${EMAIL_TOOL_API_ENDPOINTS.group.add}?name=${groupName}`);
    return { success: true, msg: res?.data?.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const addContact = async (payload) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.contact.add, payload);
    return { success: true, msg: res?.data?.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const importContact = async (payload) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.contact.bulk_create, payload);
    return { success: true, msg: res?.data?.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const deleteContact = async (groupId, email) => {
  try {
    const res = await axios.delete(`${EMAIL_TOOL_API_ENDPOINTS.contact.delete}?group=${groupId}&email=${email}`);
    return { success: true, msg: res?.data?.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

// CREATE EMAIL ASSET
export const createEmailAsset = async (payload) => {
  /**
   * Structure
   * {
        "title": "BÁN NHÀ MỚI HOÀN THIỆN! BÁN NHÀ MỚI HOÀN THIỆN!👉 ",
        "description": "Dear anh trai đẹp trai test tin thử coi nó ra sao",
        "email_details": {
            "subject": "test tin đăng",
            "sender": "taiinguyen2.1.7@gmail.com",
            "receiption": "1,",
            "tracking" : {
                "open": true,
                "click": true
            }
        },
        "type":"EMAIL"
      }
   */
  try {
    const { title, description, subject, sender, receiption, open, click, senderType, template, content } = payload;

    const data = {
      title,
      description,
      email_details: {
        content,
        subject,
        sender,
        sender_type: senderType,
        receiption,
        template: template?.id,
        tracking: {
          open,
          click,
        },
      },
      type: 'EMAIL',
    };

    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.campaign.create_asset, data);
    return { success: true, msg: res?.data?.messages, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const updateCampaign = async (uuid, payload) => {
  try {
    const { title, description, subject, sender, receiption, open, click, senderType, template } = payload;

    const data = {
      title,
      description,
      email_details: {
        subject,
        sender,
        sender_type: senderType,
        receiption,
        template: template?.id,
        tracking: {
          open,
          click,
        },
      },
      type: 'EMAIL',
    };

    const res = await axios.put(EMAIL_TOOL_API_ENDPOINTS.campaign.edit(uuid), data);
    return { success: true, msg: res?.data?.messages, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const getContactStats = async (range = 7) => {
  try {
    const res = await axios.get(`${EMAIL_TOOL_API_ENDPOINTS.contact.stats}?range=${range}`);
    return { success: true, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const getContactsList = async (page = 1, paramsObj = {}) => {
  try {
    const searchParams = new URLSearchParams({ ...paramsObj, page });
    const url = `${EMAIL_TOOL_API_ENDPOINTS.contact.list}?${searchParams}`;
    const {
      data: { results, count, allIds },
    } = await axios.get(url);

    let temp = results || [];

    temp = temp?.map((item) => ({ ...item, ...(item?.data || {}) }));

    return { results: temp, count, allIds };
  } catch (error) {
    console.log(error);
    return { results: [], count: 0, allIds: [] };
  }
};

export const getContactIdsOfGroup = async (contactGroupId) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.contact.read, {
      attribute_name: 'id',
      filter: {
        group_id: contactGroupId,
      },
    });
    return { success: true, msg: res?.data?.messages, ids: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const getContactsEmail = async (ids, page = 1, pageSize = PAGE_SIZE_CONVERT_IDS_TO_EMAIL) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.contact.read, {
      page,
      page_size: pageSize,
      attribute_name: 'email',
      filter: {
        id__in: ids,
      },
    });
    return { success: true, msg: res?.data?.messages, response: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const getContactsEmailOfGroupContacts = async (ids, page = 1, pageSize = PAGE_SIZE_CONVERT_IDS_TO_EMAIL) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.contact.read, {
      page,
      page_size: pageSize,
      attribute_name: 'email',
      filter: {
        group_id__in: ids,
      },
    });
    return { success: true, msg: res?.data?.messages, response: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const getLatestAssetsMail = async () => {
  try {
    const res = await axios.get(`api/v1/assets/?ordering=-updated_at&type=EMAIL`);
    return res?.data;
  } catch (error) {
    console.log(error);
    return { msg: error?.messages || error?.message || error?.details };
  }
};

export const createEmailCampaign = async (payload) => {
  try {
    const { contactId, assetId, emailId } = payload;

    const data = {
      email_contact_id: contactId,
      asset_email_id: assetId,
      email_id: emailId,
      sent_at: new Date(),
    };

    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.campaign.create, data);
    return { success: true, msg: res?.data?.messages, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const syncStatsEmailCampaign = async (emailId, data) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.campaign.sync, { email_id: emailId, ...data });
    return { success: true, msg: res?.data?.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const findContactEmail = async (email) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.contact.detail, {
      email,
    });
    return { success: true, msg: res?.data?.messages, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const findContactInfo = async (email) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.contact.info, {
      email,
    });
    return { success: true, msg: res?.data?.messages, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const AICheckContent = async (content) => {
  try {
    const res = await axios.post(EMAIL_TOOL_API_ENDPOINTS.ai.check_content, {
      content,
    });
    return { success: true, msg: res?.data?.messages, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};
