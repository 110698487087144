// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

// custom css
import './css/custom.css';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
// import { Auth0Provider } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
// Sentry Log
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// redux
import { persistor, store } from './redux/store';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/JWTContext';
// import { AuthProvider } from './contexts/Auth0Context';
// import { AuthProvider } from './contexts/FirebaseContext';
// import { AuthProvider } from './contexts/AwsCognitoContext';
import { WebSocketProvider } from './contexts/WSContext';

import { swUpdate } from './redux/slices/sw';
//
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import history from './routes/history';

// ----------------------------------------------------------------------
if (process.env.REACT_APP_SENTRY_DISABLE && JSON.parse(process.env.REACT_APP_SENTRY_DISABLE) === true) {
  console.log('disabled sentry');
} else {
  Sentry.init({
    dsn: 'https://eab7c9faa13f406f9227223e3f7be178@o4504009928540160.ingest.us.sentry.io/4504009931489280',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENVIRONMENT,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (typeof error === 'string' && error.indexOf('<!DOCTYPE html>') !== -1) {
        return null;
      }
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE_NAME,
  });
}

let GG_TRACKING_ID = 'G-BWF8243404';
let MS_CLARITY_ID = 'ibljnsi3af';
const ZOHO_WIDGET_CODE = 'd753d57d2f9988fa4c28bdcabdee7312ecac1ecfa6066d2976c56ad4b1fff64b';
let COZE_BOT_ID = null;
let PAYPAL_SOURCE = null;
switch (window.location.hostname) {
  case 'fbtool.net':
  case 'dev.fbtool.net':
  case 'beta.fbtool.net':
  case 'localhost':
    GG_TRACKING_ID = 'G-6LN36E912G';
    MS_CLARITY_ID = 'lshkrg8scg';
    COZE_BOT_ID = '7371424179046023169';
    PAYPAL_SOURCE = process.env.REACT_APP_PAYPAL_SOURCE;
    break;
  case 'toptoptool.net':
  case 'dev.toptoptool.net':
    GG_TRACKING_ID = 'G-0ZWRYW9MSN';
    MS_CLARITY_ID = 'oifp372mlh';
    COZE_BOT_ID = '7371424179046023169';
    PAYPAL_SOURCE = process.env.REACT_APP_PAYPAL_SOURCE;
    break;
  case 'mailingtool.net':
  case 'dev.mailingtool.net':
    GG_TRACKING_ID = 'G-8MJKQNFQ0D';
    MS_CLARITY_ID = 'p20yzh8ua3';
    COZE_BOT_ID = '7371424179046023169';
    PAYPAL_SOURCE = process.env.REACT_APP_PAYPAL_SOURCE;
    break;
  default:
    break;
}

// Google Analytics
const options = {
  gaOptions: {
    debug_mode: process.env.ENVIRONMENT === 'dev',
  },
};
console.log(`init GA4: ${GG_TRACKING_ID}`);
ReactGA.initialize(GG_TRACKING_ID, options);

// PayPal
if (PAYPAL_SOURCE) {
  const scriptPaypalHead = document.createElement('script');
  scriptPaypalHead.setAttribute('crossorigin', 'anonymous');
  scriptPaypalHead.setAttribute('src', PAYPAL_SOURCE);
  scriptPaypalHead.setAttribute('async', 'true');
  document.head.appendChild(scriptPaypalHead);
}

// Microsoft clarity heatmap, analytics, ...
const script = document.createElement('script');
script.setAttribute('type', 'text/javascript');

const code = `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${MS_CLARITY_ID}");`;

script.appendChild(document.createTextNode(code));
document.body.appendChild(script);

// COZE AI
if (COZE_BOT_ID && process.env.ENVIRONMENT === 'dev') {
  const scriptSrc = document.createElement('script');
  scriptSrc.setAttribute('type', 'text/javascript');
  scriptSrc.setAttribute(
    'src',
    'https://sf-cdn.coze.com/obj/unpkg-va/flow-platform/chat-app-sdk/0.1.0-beta.2/libs/oversea/index.js'
  );
  document.body.appendChild(scriptSrc);

  scriptSrc.addEventListener('load', () => {
    const scriptCoze = document.createElement('script');
    scriptCoze.setAttribute('type', 'text/javascript');
    const codeCoze = `
      new CozeWebSDK.WebChatClient({
        config: {
          bot_id: '${COZE_BOT_ID}',
        },
        componentProps: {
          title: 'Assistant',
          icon: 'https://app-uptin-vn.s3.amazonaws.com/avatar/chatbot.png'
        },
      });
    `;
    scriptCoze.appendChild(document.createTextNode(codeCoze));
    document.body.appendChild(scriptCoze);
  });
}

if (process.env.ENVIRONMENT === 'production') {
  // ZOHO Sales IQ
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  const url = 'https://salesiq.zoho.com/widget';
  const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${ZOHO_WIDGET_CODE}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

  script.appendChild(document.createTextNode(code));
  document.body.appendChild(script);
}

// Fix translate error from browser

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  // eslint-disable-next-line func-names
  Node.prototype.removeChild = function (child, ...res) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, [child, ...res]);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  // eslint-disable-next-line func-names
  Node.prototype.insertBefore = function (newNode, referenceNode, ...res) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, [newNode, referenceNode, ...res]);
  };
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <Auth0Provider
  //   domain={process.env.REACT_APP_AUTH0_DOMAIN}
  //   clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
  //   authorizationParams={{
  //     redirect_uri: window.location.origin,
  //   }}
  // >
  <AuthProvider>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <WebSocketProvider>
          <HelmetProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <CollapseDrawerProvider>
                  <HistoryRouter history={history}>
                    {/* <BrowserRouter> */}
                    <App />
                    {/* </BrowserRouter> */}
                  </HistoryRouter>
                </CollapseDrawerProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </HelmetProvider>
        </WebSocketProvider>
      </PersistGate>
    </ReduxProvider>
  </AuthProvider>
  // </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => store.dispatch(swUpdate({ payload: registration })),
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
