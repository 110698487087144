import { useContext } from 'react';
import { PostCommentContext } from '../context/PostCommentContext';

const usePostComment = () => {
  const context = useContext(PostCommentContext);

  if (!context) throw new Error('PostCommentContext context must be use inside PostCommentProvider');

  return context;
};

export default usePostComment;
