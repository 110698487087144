import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// layouts
import MailToolLayout from '../layouts/mail';
import Page404 from '../pages/Page404';
import { GroupContactProvider } from '../sections/whitelabel/mail/components/recipient/context/GroupContactContext';
import { ComposeProvider } from '../sections/whitelabel/mail/compose/context/ComposeContext';
import { lazyRetry, Loadable } from './lazy';
import { PATH_MAIL_TOOL } from './paths';
// =====================================================================

const MailDashboard = Loadable(lazy(() => lazyRetry(() => import('../pages/whitelabel/mail/MailDashboard'))));

const mailRoutes = [
  // Main Routes
  {
    path: '*',
    element: <LogoOnlyLayout />,
    children: [
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  {
    path: '/',
    element: <MailToolLayout />,
    children: [
      { element: <Navigate to={PATH_MAIL_TOOL.compose.root} replace />, index: true },
      {
        path: 'compose',
        element: (
          <ComposeProvider>
            <GroupContactProvider>
              <MailDashboard />
            </GroupContactProvider>
          </ComposeProvider>
        ),
        index: true,
      },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> },
];

export default mailRoutes;
