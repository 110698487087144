import { IS_PRODUCTION } from '../../config';

export const PAYPAL_CONTAINER_ID = `paypal-container`;

// Hashmap paypal package options
export const PAYPAL_CODE_MAP = IS_PRODUCTION
  ? {
      // PRODUCTION
      // FBTool.net
      fbtool_monthly_1: 'S5HAHZYHBGMG4',
      fbtool_annual_1: 'LV6JC4WG2ZEEJ',
      fbtool_monthly_2: '5RV392LK2SPLC',
      fbtool_annual_2: 'B5UACUTFPB7NU',
      fbtool_monthly_3: '5974RJJDHQEKN',
      fbtool_annual_3: 'PNCCPXRU5C6EN',
      fbtool_monthly_4: '3U9RJ6J5S9C46',
      fbtool_annual_4: '5G64B75CSL4LS',
      // TopTopTool.net
      tiktoktool_monthly_1: 'ZBW32AUHCSWLG',
      tiktoktool_annual_1: 'VCE7FYJD9TCD4',
      tiktoktool_monthly_2: 'QTJLSNKEEXQN2',
      tiktoktool_annual_2: 'G8RGALXM2PEZS',
      // MailingTool.net
      gmailtool_monthly_1: 'NL83BJ7DES7XG',
      gmailtool_annual_1: '28R8NVFWBX9VA',
      gmailtool_monthly_2: 'VN8DHEB5JJR66',
      gmailtool_annual_2: 'EEEMVXMMGBW2C',
      gmailtool_monthly_3: 'U9PAGL2ZUGTTY',
      gmailtool_annual_3: 'KG78M3YZCHRJ6',
    }
  : {
      // SANDBOX
      // FBTool.net
      fbtool_monthly_1: 'GZZV2MELCR5G4',
      fbtool_annual_1: 'S32L47DDDAG5N',
      fbtool_monthly_2: 'EZPJ7G67848DW',
      fbtool_annual_2: '2B3JXZ5Q6FD8J',
      fbtool_monthly_3: 'KBCKWQTXDCYWS',
      fbtool_annual_3: '24DD3Q6S6KTH6',
      fbtool_monthly_4: '4HJPLMCVQTYX4',
      fbtool_annual_4: 'NMVFZ47BB3P7A',
      // TopTopTool.net
      tiktoktool_monthly_1: '93YSAU4FB2AB6',
      tiktoktool_annual_1: 'DSSF5XU95H8LE',
      tiktoktool_monthly_2: '7AUJLJL23YU9E',
      tiktoktool_annual_2: '34FKXU75Z2EUA',
      // MailingTool.net
      gmailtool_monthly_1: 'T9SPLM3USXVFY',
      gmailtool_annual_1: 'SJJ3776X9NJKA',
      gmailtool_monthly_2: 'CP2X57XPLGF58',
      gmailtool_annual_2: '82GGCL7EQNQRS',
      gmailtool_monthly_3: 'PZUAVX2JJT8PC',
      gmailtool_annual_3: 'MKW9J4SZ4RK86',
    };

export const getPaypalOptions = (packageName) => PAYPAL_CODE_MAP[packageName];
