import PropTypes from 'prop-types';
import { createContext, useMemo, useRef, useState } from 'react';
import { HOST_API, MX_WORKER } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import usePostMessageRequest from '../../../hooks/usePostMessageRequest';
import { EmitterService } from '../../../utils/event';
import { ExtractResponse } from '../../../utils/tool';

// In seconds
const ABORT_MSG = 'Operation canceled';

const initialState = {
  // Emitter
  emitter: { on: () => {}, off: () => {}, send: () => {} },
  restartSignal: () => {},
  validateMX: () => {},
  getGmails: () => {},
  composeMail: (to, subject, body, attachments = [], type = 'normal', accountIndex = 0) => {},
  switchAccount: (email) => {},
  checkLimitOfAccount: (accountIndex, baseLimit = 500) => Promise.resolve(),
  stopQueue: () => {},
};

const MailToolFunctionContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
MailToolFunctionProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function MailToolFunctionProvider({ children }) {
  const { sendRequest } = usePostMessageRequest();

  const [emitter, setEmitter] = useState(new EmitterService());

  const responseHelper = new ExtractResponse();

  const { user } = useAuth();

  // Control flow, queue, tasks
  const controller = useRef(null);
  const signal = useRef(null);

  // ===================================================================
  const restartSignal = () => {
    // Restart signal
    const newController = new AbortController();
    controller.current = newController;
    signal.current = newController?.signal;

    signal?.current?.addEventListener('abort', () => {
      throw new Error(ABORT_MSG);
    });
  };

  const validateMX = async (email) => {
    try {
      console.log('validateMX', email);
      if (!email) {
        return false;
      }
      const domain = email.split('@').pop();
      const mxRecords = await getMxRecords(domain);

      if (mxRecords.length === 0) {
        return new Response('No MX records found', { status: 404 });
      }

      // mail server check must be perform server-side or from Cloudflare worker
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getMxRecords = async (domain) => {
    console.log('getMxRecords', domain);

    const response = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
    const data = await response.json();

    return data.Answer || []; // Return the MX records
  };

  const getGmails = async (baseLimit = 500) => {
    return await sendRequest('GET_GMAILS', { limit: baseLimit });
  };

  const composeMail = async (to, subject, body, attachments = [], contentType = 'normal', accountIndex = 0) => {
    const openEndpoint = `${MX_WORKER}/tracker/image.png?id=email_id`;

    return await sendRequest(
      'COMPOSE_MAIL',
      { accountIndex, to, subject, body, attachments, contentType, openEndpoint },
      signal?.current
    );
  };

  const switchAccount = async (email) => {
    return await sendRequest('SWITCH_ACCOUNT', { email }, signal?.current);
  };

  const checkLimitOfAccount = async (accountIndex, baseLimit = 500) => {
    const res = await sendRequest('CHECK_LIMIT_ACCOUNT', { accountIndex, limit: baseLimit }, signal?.current);
    return res?.result || 0;
  };

  // Stop current queue
  const stopQueue = async () => {
    try {
      controller?.current?.abort();
      emitter?.send('stop');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MailToolFunctionContext.Provider
      value={useMemo(
        () => ({
          // Emitter
          emitter,
          stopQueue,
          restartSignal,
          // Funcs
          validateMX,
          getGmails,
          composeMail,
          switchAccount,
          checkLimitOfAccount,
        }),
        [emitter]
      )}
    >
      {children}
    </MailToolFunctionContext.Provider>
  );
}

export { MailToolFunctionProvider, MailToolFunctionContext };
