import { useSnackbar } from 'notistack';
import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef } from 'react';
import useAuth from '../../../hooks/useAuth';

// hooks
import { useStatsGuard } from '../../../hooks/lead';
import useMailToolFunction from '../../../hooks/useMailToolFunction';
import useRefCustom from '../../../hooks/useRefCustom';
import useLocales from '../../../hooks/useLocales';
import { getGmailAccounts } from '../../../sections/whitelabel/mail/helper';
import { fDate } from '../../../utils/formatTime';
import axiosInstance from '../../../utils/axios';
import { MX_WORKER } from '../../../config';

const initialState = {
  accounts: [],
  isFetching: false,
  remainsMail: 500,
  dailyMailLimit: 500,
  // Main funcs
  onLoadAccounts: () => {},
  getCurrentEmails: () => {},
  switchAccount: (email) => {},
  markAsSent: (email) => {},
  getAccountsLimit: () => Number.NaN,
  getMailSentToday: () => Number.NaN,
  getMailDailyLimit: () => Number.NaN,
  stopQueue: () => {},
};

const MAILINGTOOL_BOUNCED_UPDATE = `${MX_WORKER}/api/bounced/`;
const QUEUE_NAME = {
  ACCOUNTS: 'ACCOUNTS',
};

const DEFAULT_LIMIT = 500;

const MailContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
MailProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function MailProvider({ children }) {
  const { getGmails, checkLimitOfAccount, stopQueue } = useMailToolFunction();

  const { canUseTier } = useStatsGuard();

  const { translate: tMessages } = useLocales('mailing.messages');

  const { enqueueSnackbar } = useSnackbar();

  const { user, configs } = useAuth();

  const [dailyMailLimit, setDailyMailLimit, dailyMailLimitRef] = useRefCustom(DEFAULT_LIMIT);

  const [remainsMail, setRemainsMail] = useRefCustom(DEFAULT_LIMIT);

  const [accounts, setAccounts, accountsRef] = useRefCustom([]);

  const [isFetching, setIsFetching, isFetchingRef] = useRefCustom(false);

  const [, setFetchQueues, fetchQueuesRef] = useRefCustom([]);

  const [mailSentToday, setMailSentToday, mailSentTodayRef] = useRefCustom(0);

  const lastCheck = useRef();

  // console.log('mailSentToday/dailyMailLimit: ', mailSentToday, '/', dailyMailLimit);

  useEffect(() => {
    const handleMessage = async (event) => {
      const { payload, type } = event?.data || {};
      if (type === 'R_MAIL_BOUNCED_DATA' && payload?.data) {
        console.log('received: ', payload?.data);
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          const config = {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          await axios.post(MAILINGTOOL_BOUNCED_UPDATE, payload?.data, config);
        }
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  //  ---------------------------------------------------------------------
  const addToQueues = (name) => {
    const fetchQueues = fetchQueuesRef?.current || [];
    setFetchQueues(_.uniq([...fetchQueues, name]));
  };

  const removeQueue = (name) => {
    const fetchQueues = fetchQueuesRef?.current || [];
    const excluded = fetchQueues?.filter((queue) => queue !== name);
    setFetchQueues(excluded);
  };

  const allowRunQueue = (name) => {
    const fetchQueues = fetchQueuesRef?.current || [];
    const index = fetchQueues?.indexOf(name);
    if (index === -1) {
      addToQueues(name);
    }
    return index === -1;
  };

  //  ---------------------------------------------------------------------

  const onLoadAccounts = async () => {
    if (allowRunQueue(QUEUE_NAME.ACCOUNTS)) {
      setIsFetching(true);
      const { result } = await getGmails(DEFAULT_LIMIT);
      if (result) {
        setAccounts(result);
      }
      setIsFetching(false);
      removeQueue(QUEUE_NAME.ACCOUNTS);
    }
  };

  useEffect(() => {
    if (user && !_.isEmpty(configs)) {
      const max = getAccountsLimit();
      const DAILY_MAIL_PER_ACCOUNT = configs?.daily_mail_per_account || DEFAULT_LIMIT;
      setMailSentToday(user?.mail_sent?.total || 0);
      setDailyMailLimit(DAILY_MAIL_PER_ACCOUNT * max);
      onLoadAccounts();
      lastCheck.current = new Date();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, configs]);

  const getCurrentEmails = () => {
    const accounts = [...(accountsRef?.current || [])];
    return accounts?.filter((item) => item?.is_current);
  };

  const checkLimitOfEmail = async (accountIndex) => {
    if (accountIndex !== -1) {
      const remains = await checkLimitOfAccount(accountIndex, DEFAULT_LIMIT);
      setRemainsMail(remains);
      return remains;
    }
    return dailyMailLimitRef?.current;
  };

  const getAccountsLimit = useCallback(() => {
    return user?.mailtool?.accounts || 1;
  }, [user]);

  const switchAccount = useCallback(
    async (email) => {
      const acc = [...accountsRef?.current];
      const index = acc?.findIndex((item) => item?.email === email);

      const currentSelected = getCurrentEmails();

      if (index !== -1) {
        const isCurrent = !acc[index]?.is_current;

        // // Check tier
        // const max = getAccountsLimit();
        // if (isCurrent && currentSelected?.length >= max) {
        //   enqueueSnackbar(tMessages('limit_accounts', { amount: max }), { variant: 'error' });
        //   return;
        // }

        acc[index].is_current = isCurrent;
        const { remains } = acc[index];

        if (isCurrent && !remains) {
          const accountIndex = acc[index]?.index || 0;
          const remains = await checkLimitOfEmail(accountIndex);
          acc[index].remains = remains;
        }
      }
      setAccounts(acc);
    },
    [getAccountsLimit]
  );

  const getMailSentToday = () => mailSentTodayRef?.current || 0;

  const getMailDailyLimit = () => dailyMailLimitRef?.current || DEFAULT_LIMIT;

  const increaseMailSentToday = async (value = 1) => {
    // Check reset by new day ?
    const now = new Date();
    const previousCheck = lastCheck.current;

    if (fDate(now) !== fDate(previousCheck || new Date())) {
      // Fetch user
      const res = await axiosInstance.get('api/v1/users/me/');
      const user = res?.data;
      setMailSentToday(user?.mail_sent?.total || 0);
    }

    const current = mailSentTodayRef?.current || 0;
    // Update
    lastCheck.current = new Date();
    setMailSentToday(current + value);
  };

  const markAsSent = (email) => {
    try {
      const acc = [...accountsRef?.current];
      const index = acc?.findIndex((item) => item?.email === email);
      if (index !== -1) {
        increaseMailSentToday();
        const ownKey = Object.prototype.hasOwnProperty.call(acc[index], 'remains');
        const current = ownKey ? acc?.[index]?.remains : DEFAULT_LIMIT;
        acc[index].remains = current - 1;
      }
      setAccounts(acc);
    } catch (error) {
      console.log(error);
    }
  };

  //  ---------------------------------------------------------------------
  return (
    <MailContext.Provider
      value={useMemo(
        () => ({
          accounts,
          remainsMail,
          isFetching,
          // Main funcs
          onLoadAccounts,
          getCurrentEmails,
          switchAccount,
          markAsSent,
          getAccountsLimit,
          getMailSentToday,
          getMailDailyLimit,
          stopQueue,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [accounts, isFetching, remainsMail]
      )}
    >
      {children}
    </MailContext.Provider>
  );
}

export { MailProvider, MailContext };
