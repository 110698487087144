import { useCallback } from 'react';
import useToolFunction from '../../../../../hooks/useToolFunction';
import { delayFunc, randomInRange } from '../../../../../utils/others';

export function useRemoveFriendsOutgoingRequests() {
  const { getSignal, getFriendsOutgoingRequests, stopQueue, cancelFriendRequest, restartSignal } = useToolFunction();

  const onCancelFriendRequests = useCallback(async (settings, callback, onFinish) => {
    try {
      const { delay } = settings;

      restartSignal();
      let data = await getFriendsOutgoingRequests(10);
      if (data) {
        let requests = data?.requests || [];

        while (requests?.length !== 0) {
          for (const req of requests) {
            const success = await cancelFriendRequest(req?.id);
            if (callback && success) {
              callback({
                profile: req,
                success,
              });
            }
            const delayTime = randomInRange(delay) * 1000;
            await delayFunc(delayTime || 3000, getSignal());
          }
          const nextCursor = data?.nextCursor;
          if (nextCursor) {
            data = await getFriendsOutgoingRequests(10, nextCursor);
            requests = data?.requests || [];
          } else {
            requests = [];
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      onFinish();
    }
  }, []);
  return {
    onCancelFriendRequests,
  };
}
