import PropTypes from 'prop-types';
import { CommonToolProvider } from '../../contexts/CommonToolContext';
import { MailToolFunctionProvider } from '../../contexts/tool/mail/MailToolFunctionContext';
import { StatsGuardProvider } from '../../contexts/StatsGuardContext';
import { MailProvider } from '../../contexts/tool/mail/MailContext';

function CombinedContextLayout({ children }) {
  return (
    <StatsGuardProvider>
      <MailToolFunctionProvider>
        <MailProvider>
          <CommonToolProvider>{children}</CommonToolProvider>
        </MailProvider>
      </MailToolFunctionProvider>
    </StatsGuardProvider>
  );
}

CombinedContextLayout.propTypes = {
  children: PropTypes.node,
};

export default CombinedContextLayout;
