import axiosInstance from '../axios';
import { convertHtmlToString } from '../formatString';
import { delayFunc, randomInRange } from '../others';

const LIMIT_TEXT = ['giới hạn', 'limit'];
const FBTOOL_AI_ENDPOINT = 'api/v2/tool/spin/';
const MAILINGTOOL_AI_ENDPOINT = 'api/v2/tool/email/campaign/spin/';
const ABS_TEXT = 'AI';

export const getPlaceholder = (htmlSnippet) => {
  try {
    const strongContentRegex = /{{([\s\S]*?)}}/g;

    const matches = [];
    let match = strongContentRegex.exec(htmlSnippet);
    while (match !== null) {
      matches.push(match[1]);
      match = strongContentRegex.exec(htmlSnippet);
    }
    return matches;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const convertDescriptionToTemplate = (description, convertToString = true) => {
  const strongTagRegex = /{{(.*?)}}/g;

  let index = 1;

  // Replace all <strong> tags and their content with structured placeholders
  const replacedText = description.replace(strongTagRegex, () => {
    const replacement = `{{text${index}}}`;

    index += 1;

    return replacement;
  });
  const content = replacedText || description;
  return convertToString ? convertHtmlToString(content) : content;
};

export const getSingleSpin = async (id, spinData, isMailingTool = false) => {
  try {
    let bodyStructure = ``;
    const info = {};

    if (spinData) {
      bodyStructure += `\tpost:{"text1": [options], ...},`;
      info.post = {
        content: spinData?.content,
        text_to_be_replace: spinData?.partSelected?.reduce((res, part, index) => {
          res[`text${index + 1}`] = part;
          return res;
        }, {}),
        write_down: 10,
      };
    }

    bodyStructure = `{\n${bodyStructure}\n}`;

    const { success, data } = await spinContent(id, bodyStructure, info, isMailingTool);

    if (success && data) {
      return spinData;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const spinContent = async (id, bodyStructure, info, isMailingTool = false) => {
  try {
    if (!bodyStructure || !info || !id) {
      return null;
    }
    // Should delay here. Reason: Sometimes AI responds too quickly while the code has not finished running
    await delayFunc(2 * 1000);
    const { data } = await axiosInstance.post(isMailingTool ? MAILINGTOOL_AI_ENDPOINT : FBTOOL_AI_ENDPOINT, {
      id,
      question: { final_structure: bodyStructure, data: info },
    });
    return { success: !!data, data };
  } catch (error) {
    console.log(error);
    const permissionMessage = error?.detail;
    const isLimitAIRequest = LIMIT_TEXT.some(
      (item) => permissionMessage?.indexOf(item) !== -1 && permissionMessage?.indexOf(ABS_TEXT) !== -1
    );
    // if (isLimitAIRequest) {
    //   enqueueSnackbar(permissionMessage || tGlobal('server_error'), { variant: 'error' });
    // }

    return { success: false };
  }
};

export const randomSpinData = (spinContents) => {
  try {
    return Object.keys(spinContents)?.reduce((res, key) => {
      const len = spinContents[key]?.length;
      const rad = randomInRange({ from: 0, to: len - 1 });
      if (spinContents[key][rad]) {
        res[key] = spinContents[key][rad] || '';
      }
      return res;
    }, {});
  } catch (error) {
    console.log(error);
    return {};
  }
};
