const vn = {
  info: {
    following: 'Đã follow',
    followers: 'Follower',
    like: 'Thích',
  },
  interact: {
    search_place: 'Nơi tìm',
    setting: {
      keyword: 'Từ khóa',
      comment: 'Bình luận',
      heart: 'Thả tim',
      view_profile: 'Xem profile',
      follow: 'Follow',
      save: 'Lưu',
      inbox: 'Nhắn tin',
      repost: 'Đăng lại',
    },
    types: {
      following: 'Đã follow',
      follower: 'Follower',
      friend: 'Bạn bè',
      recommend: 'Được đề xuất',
      keyword: 'Từ khóa',
    },
    form: {
      comment_amount: 'Số bài',
      like_amount: 'Số bài',
      clip_amount: 'Số clip',
      amount: 'Số lượng',
      action_amount: 'Hành động',
      keyword: 'Từ khóa',
      channel: 'Số kênh (Tối đa: {{amount}})',
      errors: {
        maximum: 'Tối đa {{amount}}',
        minimum: 'Tối thiểu {{amount}}',
        required: 'Đây là trường bắt buộc',
      },
    },
  },
  lead: {
    categorize: {
      title: 'Phân loại',
      seller: 'Người bán',
      buyer: 'Người mua',
      unknown: 'Không xác định',
    },
    action: {
      title: 'Hành động',
      follow: 'Follow (Beta)',
      inbox: 'Nhắn tin (Beta)',
      comment: 'Bình luận',
      heart: 'Thả tim',
    },
    table: {
      head: {
        post: 'Nội dung',
        name: 'Tên người đăng',
        uid: 'UID',
        comment: 'Bình luận',
        follow: 'Follow',
        type: 'Loại',
        inbox: 'Nhắn tin',
        like: 'Thích',
        categorize: 'Phân loại',
      },
    },
  },
  stats: {
    days: '{{days}} ngày',
    overview: {
      title: 'Tổng quát',
      video: `{{video}} Lượt xem video`,
      profileView: `{{profileView}} Lượt xem hồ sơ`,
      like: `{{like}} Lượt thích`,
      comment: `{{comment}} Lượt bình luận`,
      share: `{{share}} Chia sẻ`,
    },
    viewer: {
      title: 'Người xem',
      total: `{{total}} Tổng người xem`,
      male: `{{male}} Nam`,
      female: `{{female}} Nữ`,
      age: `{{age_percent}} Tuổi từ {{age}}`,
      country_percent: `{{country_percent}} {{country}}`,
    },
    follower: {
      title: 'Người theo dõi',
      total: `{{total}} Tổng người theo dõi`,
      male: `{{male}} Nam`,
      female: `{{female}} Nữ`,
      age: `{{age_percent}} Tuổi từ {{age}}`,
      country_percent: `{{country_percent}} {{country}}`,
    },
    traffic: {
      title: 'Nguồn traffic',
      trend: 'Xu hướng',
      profile: 'Hồ sơ cá nhân',
      search: 'Tìm kiếm',
      follower: 'Đang theo dõi',
      music: 'Nhạc',
    },
  },
  messages: {
    fill_keyword: 'Vui lòng điền từ khóa',
  },
  export: {
    comment: 'Bình luận',
    heart: 'Thả tim',
    view_profile: 'Xem profile',
    save: 'Lưu',
    repost: 'Đăng lại',
    follow: 'Theo dõi',
    type: 'Loại',
    success: 'Thành công',
    fail: 'Thất bại',
    post: 'Bài đăng',
    post_uid: 'UID bài đăng',
    status: 'Tình trạng',
    actor: 'Người đăng',
    actor_uid: 'UID người đăng',
    categorize: 'Phân loại',
    inbox: 'Nhắn tin',
    like: 'Thích',
    name: 'Tên',
    source: {
      comment: 'Bình luận',
      post: 'Bài đăng',
      reshare: 'Người chia sẻ',
      commenter: 'Người bình luận',
      reactor: 'Người tương tác',
    },
  },
  popup: {
    pricing: {
      title: 'Công cụ hỗ trợ tương tác trên ',
      sub: 'Tiktok',
      heading: 'BẢNG GIÁ',
      features: ['Không cần đăng nhập ID và mật khẩu Tiktok 🔒', 'AI viết lại nội dung miễn phí', 'Lưu nhóm yêu thích'],
      tier: 'Bậc',
      tiers: [
        {
          tier: 1,
          name: 'BASIC',
          cost: {
            month: 100000,
            year: 50000,
          },
          features: [
            {
              text: 'Bình luận',
              info: 'Bình luận',
            },
            {
              text: 'AI spin',
              info: 'Mỗi lần đăng bài, tool tự động dùng AI viết lại nội dung để né Spam.',
            },
            {
              text: 'Thả tim',
              info: 'Thả tim',
            },
            {
              text: 'Đăng lại',
              info: 'Đăng lại',
            },
            {
              text: 'Lưu nhóm người dùng yêu thích',
              info: 'Lưu nhóm người dùng yêu thích',
            },
            {
              text: 'Follow',
              info: 'Follow',
            },
            {
              text: 'Lưu video',
              info: 'Lưu lại',
            },
            {
              text: 'Xem profile',
              info: 'Xem profile',
            },
          ],
        },
        {
          tier: 2,
          name: 'PLUS',
          cost: {
            month: 200000,
            year: 100000,
          },
          features: [
            {
              text: 'Tìm kiếm khách hàng',
              info: 'AI tìm kiếm khách hàng',
            },
            {
              text: 'Tìm trong quảng cáo, link đã lưu',
              info: 'Tìm trong quảng cáo, link đã lưu',
            },
            {
              text: 'Gửi tin nhắn',
              info: 'Gửi tin nhắn',
            },
            {
              text: 'Tự động lựa chọn sản phẩm/dịch vụ',
              info: 'Tự động lựa chọn sản phẩm/dịch vụ phù hợp nhất',
            },
          ],
        },
      ],
      monthly: 'Hàng tháng',
      semi_annual: 'Nửa năm',
      annual: 'Hàng năm',
      month_value: '100K',
      annual_value: '50K',
      discount: 'Tiết kiệm tới {{percent}}%',
      bill_annual: 'Năm',
      bill_monthly: 'Tháng',
    },
  },
};

export default vn;
