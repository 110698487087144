import {
  Box,
  Card,
  Dialog,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import Iconify from '../../../../components/Iconify';
import Image from '../../../../components/Image';
import { useStatsGuard } from '../../../../hooks/lead';
import useAuth from '../../../../hooks/useAuth';
import useBoolean from '../../../../hooks/useBoolean';
import useLocales from '../../../../hooks/useLocales';
import { numberWithCommas } from '../../../../utils/formatNumber';
import { PAYPAL_CONTAINER_ID } from '../../../../utils/tool/paypal';

// import cssStyles from '../../../../utils/cssStyles';

// const BlurEffect = styled(Box)(({ theme }) => ({
//   ...cssStyles(theme).bgBlur({ blur: 0.4, opacity: 0.4 }),
//   position: 'absolute',
//   boxShadow: 'none',
//   height: '100%',
//   width: '100%',
//   zIndex: theme.zIndex.appBar + 1,
//   transition: theme.transitions.create(['width', 'height'], {
//     duration: theme.transitions.duration.shorter,
//   }),
// }));

const SELECT_TIER = 'select-tier';

PricingPopup.propTypes = {
  open: PropTypes.bool,
  onBuySubscription: PropTypes.func,
  onClose: PropTypes.func,
};

// const HotTag = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   top: 20,
//   right: 40,
//   height: 50,
//   width: 70,
//   [theme.breakpoints.down('sm')]: {
//     top: 20,
//     right: 80,
//     height: 50,
//     width: 65,
//   },
// }));

export default function PricingPopup({ open, onBuySubscription, onClose }) {
  const { user, googleLogin } = useAuth();

  const { tierEmitter } = useStatsGuard();

  const haveBuyFbTool = user?.tiktoktool?.is_bought_tiktoktool;

  const { enqueueSnackbar } = useSnackbar();

  const { translate, currentLang } = useLocales('tiktok.popup.pricing');

  // const { translate: tButton } = useLocales('tool.btn');

  const { translate: tAff } = useLocales('tool.affiliate');

  const { translate: tSME } = useLocales('dashboard.account.payment.sme');

  const { translate: tPayment } = useLocales('dashboard.account.payment');

  const { translate: tLogin } = useLocales('login');

  const annualSubscription = useBoolean(true);

  const [tierSelected, setTierSelected] = useState(1);

  const [refcode, setRefcode] = useState('');

  const packageName = useMemo(
    () => (annualSubscription.value ? `tiktoktool_annual_${tierSelected}` : `tiktoktool_monthly_${tierSelected}`),
    [annualSubscription.value, tierSelected]
  );

  const TIERS = useMemo(() => translate('tiers', { returnObjects: true }), [currentLang]);

  useEffect(() => {
    const changeTier = (tier) => {
      const maximumTiers = TIERS?.length;
      if (tier >= 0 && tier <= maximumTiers) {
        setTierSelected(tier);
      }
    };
    tierEmitter.on(SELECT_TIER, changeTier);
    return () => {
      tierEmitter.off(SELECT_TIER, changeTier);
    };
  }, [tierEmitter, TIERS]);

  const handleBuyPackage = (cardType) => {
    // console.log(`click on ${cardType}`);
    try {
      if (!cardType) {
        console.log(`invalid request`);
        return;
      }
      if (!user) {
        console.log(`user not logged in`);
        loginWithGoogle();
        return;
      }
      onBuySubscription({
        cardType,
        name: packageName,
        refcode,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loginGoogle = async (code) => {
    if (code) {
      console.log('loginGoogle');
      await googleLogin(code);
    }
    return enqueueSnackbar(tLogin('message.failure'), { variant: 'error' });
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (response) => {
      loginGoogle(response?.code);
    },
    onError: (error) => {
      console.error('useGoogleLogin error: ', error);
    },
    useOneTap: true,
    auto_select: true,
    cancel_on_tap_outside: false,
    flow: 'auth-code',
    ux_mode: 'redirect',
  });

  const moneyAttributePath = annualSubscription.value ? 'year' : 'month';

  const handleChangeTier = (newTier) => {
    setTierSelected(newTier);
  };

  const handleChangeRefcode = (e) => {
    setRefcode(e.target.value);
  };

  return (
    <Dialog
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <Box
        sx={{
          py: 3,
          px: { xs: 2, md: 3 },
          maxHeight: '100dvh',
          fontFamily: 'Public Sans',
          bgcolor: (theme) => theme.palette.grey[100],
          position: 'relative',
          color: 'common.black',
        }}
        className="scroll-bar-custom"
      >
        <Stack direction="column" justifyContent={'center'} alignItems="center" width={1}>
          <Typography fontWeight="bold" fontSize="1.8rem" textAlign="center">
            {translate('heading')}
          </Typography>
          <Stack direction="column" justifyContent={'center'} alignItems="center" gap={0.5} my={1.5}>
            <Stack direction="row" spacing={1} justifyContent="center">
              <Stack flex={1} direction="column" justifyContent={'center'}>
                <Typography
                  variant={'body1'}
                  sx={{ textWrap: 'nowrap', ...(annualSubscription.value ? { color: 'text.disabled' } : {}) }}
                >
                  {translate('bill_monthly')}
                </Typography>
              </Stack>
              <Stack flex={1} maxWidth={100} sx={{ marginLeft: 16, marginRight: 16 }}>
                <Switch checked={annualSubscription.value} onChange={annualSubscription.onToggle} />
              </Stack>
              <Stack flex={1} direction="column" justifyContent={'center'}>
                <Typography
                  variant={'body1'}
                  sx={{ textWrap: 'nowrap', ...(annualSubscription.value ? {} : { color: 'text.disabled' }) }}
                >
                  {translate('bill_annual')}
                </Typography>
              </Stack>
            </Stack>
            {/* <Typography variant="body2" sx={{ textWrap: 'nowrap', textAlign: 'center' }}>
              ({translate('discount', { percent: 50 })})
            </Typography> */}
          </Stack>
          <Stack gap={2} pb={1.5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
            {TIERS?.map((item) => (
              <Stack
                component={Card}
                key={item?.tier}
                sx={{
                  py: 1.5,
                  px: 1.5,
                  borderRadius: 2,
                  cursor: 'pointer',
                  ...(item?.tier === tierSelected && {
                    boxShadow: (theme) => `1px 1px 15px 1px ${theme.palette.primary.main}`,
                  }),
                  flex: 1,
                }}
                onClick={() => {
                  handleChangeTier(item?.tier);
                }}
              >
                <Stack justifyContent={'center'} alignItems="center" gap={2}>
                  <Stack
                    justifyContent={'center'}
                    alignItems="center"
                    px={1.5}
                    py={0.75}
                    sx={{
                      bgcolor: (theme) => theme.palette.grey[100],
                      width: 'fit-content',
                      color: 'common.black',
                      borderRadius: 0.7,
                      ...(item?.name === 'MAX' && { bgcolor: 'error.main', color: 'common.white' }),
                    }}
                    fontWeight="bold"
                  >
                    {item?.name}
                  </Stack>
                  {/* {item?.name === 'PRO' && (
                    <HotTag>
                      <Image src="/assets/smt/hot.png" alt="tag" />
                    </HotTag>
                  )} */}

                  <Stack flex={1} direction="row">
                    <Typography fontWeight={'bold'} variant={'h3'} sx={{ textWrap: 'nowrap' }}>
                      {`${numberWithCommas(item?.cost[moneyAttributePath], '.')}đ`}
                      <Typography component="span" variant="body1" textTransform={'lowercase'} color={'text.disabled'}>
                        {` /${tSME('month')}`}
                      </Typography>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="column" gap={0.5} my={2} flex={1}>
                  {item?.features?.map((item) => (
                    <Stack key={item?.text} direction="row" justifyContent={{ xs: 'center', sm: 'start' }} flex={0}>
                      <Typography lineHeight={2.5} fontSize="0.8rem">
                        {item?.text}
                      </Typography>
                      <Stack
                        ml="auto"
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                          transition: 'color 0.25s',
                          '&:hover': {
                            color: 'primary.main',
                          },
                        }}
                      >
                        <Tooltip title={<Typography variant="body2">{item?.info}</Typography>}>
                          <Stack>
                            <Iconify
                              icon="fluent:info-16-regular"
                              height={15}
                              width={15}
                              sx={{ flexShrink: 0, mt: 0.765 }}
                            />
                          </Stack>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>

          {!haveBuyFbTool && (
            <TextField
              sx={{
                my: 1,
              }}
              label={tAff('refcode')}
              onChange={handleChangeRefcode}
              value={refcode}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" edge="end">
                      <Iconify icon={'fluent:people-sync-28-filled'} width={20} height={20} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          <Typography variant="h6" mt={1} mb={1}>
            {tPayment('method')}
          </Typography>
          <Box
            spacing={2}
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              display: 'inline-flex',
              columnGap: 2,
              rowGap: 1,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper
              onClick={() => {
                handleBuyPackage('payos');
              }}
              sx={{
                px: 2,
                py: 1,
                width: 1,
                position: 'relative',
                border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                maxHeight: 42,
                maxWidth: 240,
                bgcolor: 'common.white',
                color: 'common.black',
              }}
            >
              {/* <Image alt="icon" src={'/assets/icons/payments/ic_payos.svg'} sx={{ width: 50 }} /> */}
              <Iconify icon={'mdi:bank'} width={24} height={24} />
              <Typography variant="subtitle2" flex={1} textAlign="center">
                {tPayment('domestic_wired')}
              </Typography>
            </Paper>
            <Paper
              onClick={() => {
                handleBuyPackage('payos2');
              }}
              sx={{
                px: 2,
                py: 1,
                width: 1,
                position: 'relative',
                border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                maxHeight: 42,
                maxWidth: 240,
                bgcolor: 'common.white',
                color: 'common.black',
              }}
            >
              <Image alt="icon" src={'/assets/icons/payments/ic_momo.svg'} sx={{ width: 30 }} />
              <Typography variant="subtitle2" flex={1} textAlign="center">
                MoMo
              </Typography>
            </Paper>
            <Paper
              onClick={() => {
                handleBuyPackage('paypal');
              }}
              sx={{
                px: 2,
                py: 1,
                width: 1,
                position: 'relative',
                border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                maxHeight: 42,
                maxWidth: 240,
                bgcolor: 'common.white',
                color: 'common.black',
              }}
            >
              <Image alt="icon" src={'/assets/icons/payments/ic_paypal.svg'} sx={{ width: 20 }} />
              <Typography variant="subtitle2" flex={1} textAlign="center">
                Paypal
              </Typography>
            </Paper>
          </Box>
          <Box sx={{ mt: 1 }} style={{ layout: 'horizontal' }} id={PAYPAL_CONTAINER_ID} />
        </Stack>
      </Box>
    </Dialog>
  );
}
