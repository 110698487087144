import { useContext } from 'react';
import { MailToolFunctionContext } from '../contexts/tool/mail/MailToolFunctionContext';

const useMailToolFunction = () => {
  const context = useContext(MailToolFunctionContext);

  if (!context) {
    throw new Error('useMailToolFunction context must be use inside MailToolFunctionProvider');
  }

  return context;
};

export default useMailToolFunction;
