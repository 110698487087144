import NoSleep from '@zakj/no-sleep';
import { Link, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

// hooks
import useBoolean from '../hooks/useBoolean';
import useGoogleLoginCustom from '../hooks/useGoogleLoginCustom';
import useLocales from '../hooks/useLocales';
import useRefCustom from '../hooks/useRefCustom';
import useTenant from '../hooks/useTenant';
import { useSelector } from '../redux/store';
import { modifyTime } from '../utils/formatTime';
import { compareVersions } from '../utils/others';
import { createTargetCollection, getTargetCollections, removeTargetCollection } from '../utils/tool/api';
import usePostMessageRequest from '../hooks/usePostMessageRequest';

const PROPERTY_NAME = {
  facebook: 'fbtool',
  tiktok: 'tiktoktool',
  gmail: 'gmailtool',
};

const initialState = {
  isOpenPricingModal: false,
  extensionVersion: '',
  // Version
  tenantExtensionVersion: '',
  // No sleep
  noSleep: {},
  // modal, popup
  closeLoginModal: () => {},
  closePricingModal: () => {},
  openLoginModal: () => {},
  openPricingModal: () => {},
  // ------------------
  checkCommonData: () => {},
  // Collections
  targetCollections: [],
  onCreateTargetCollection: () => {},
  onRemoveTargetCollection: () => {},
  // Task
  isTaskRunning: false,
  resetTaskRunning: () => {},
  updateTaskRunningStatus: () => {},
};

const CommonToolContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
CommonToolProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function CommonToolProvider({ children }) {
  const { sendRequest } = usePostMessageRequest();

  const { pathname } = useLocation();

  const [taskRunning, setTaskRunning] = useState({});

  const [targetCollections, setTargetCollections] = useState([]);

  const loginModal = useBoolean();

  const warningActionModal = useBoolean();

  const noSleep = new NoSleep();

  const loginWithGoogle = useGoogleLoginCustom({ redirectTo: window.location.href });

  const warningPopup = useBoolean();

  const { user: userAuth } = useAuth();

  const [user, setUserRef, userRef] = useRefCustom(null);

  useEffect(() => {
    setUserRef(userAuth);
  }, [userAuth]);

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [extensionVersion, setExtensionVersion] = useState(null);

  const isEnabledExtension = useRef(false);

  const checker = useRef();

  const extensionModal = useBoolean();

  const promoPopup = useBoolean();

  // Tenant
  const { isTool, isMailTool, isSMT, isTTTool } = useTenant();

  const { currentTenant } = useSelector((state) => state.tenant);

  const tenantExtensionVersion = currentTenant?.config?.extension_version;

  const shouldUpdateExtension = useMemo(
    () => {
      // allow later versions
      if (isTool && tenantExtensionVersion && extensionVersion) {
        console.log('tenantExtensionVersion: ', tenantExtensionVersion);
        console.log('webExtensionVersion: ', extensionVersion);
        const diff = compareVersions(extensionVersion, tenantExtensionVersion);

        return diff < 0;
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenantExtensionVersion, isTool, extensionVersion]
  );

  const toolPropertyName = useMemo(
    () => {
      if (isSMT) {
        return PROPERTY_NAME.facebook;
      }
      if (isTTTool) {
        return PROPERTY_NAME.tiktok;
      }
      if (isMailTool) {
        return PROPERTY_NAME.gmail;
      }
      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTool, currentTenant, isMailTool, isSMT, isTTTool]
  );

  useEffect(() => {
    if (shouldUpdateExtension) {
      enqueueSnackbar(translate('update_extension'), {
        variant: 'warning',
        autoHideDuration: 5000,
        action: (
          <Link target="_blank" href="https://doc.fbtool.net/" color="secondary" size="small">
            {translate('tool.btn.more')}
          </Link>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateExtension]);

  const canUseFeaturesRef = useRef();

  useEffect(() => {
    canUseFeaturesRef.current =
      userRef?.current?.[toolPropertyName]?.is_bought_lifetime ||
      (userRef?.current?.[toolPropertyName]?.expired_at &&
        modifyTime(userRef?.current?.[toolPropertyName]?.expired_at) >= new Date());
  }, [user, toolPropertyName]);

  const getExtensionVersion = async () => {
    return await sendRequest('GET_VERSION');
  };

  const checkExtensionActive = async () => {
    return await sendRequest('CHECK_ACTIVE_EXTENSION');
  };

  const onCheckActiveExtension = async () => {
    const payload = await checkExtensionActive();
    if (payload?.is_active) {
      isEnabledExtension.current = true;
      if (checker.current) {
        clearInterval(checker.current);
      }
    }
  };

  const onLoadExtensionVersion = async () => {
    const payload = await getExtensionVersion();
    if (payload?.version) {
      setExtensionVersion(payload?.version);
    }
    if (payload?.isDuplicate) {
      // Force reload
      enqueueSnackbar(translate('duplicate_extension'), {
        variant: 'warning',
        autoHideDuration: 30000,
        action: (
          <Stack direction="row" gap={2} alignItems="center">
            <Stack
              onClick={() => {
                window.location.reload();
              }}
            >
              <Link href="#" color="secondary" size="small">
                {translate('btn.reload')}
              </Link>
            </Stack>
            <Link
              target="_blank"
              href="https://doc.fbtool.net/#buoc-2-tai-va-cai-dat-tien-ich-mo-rong"
              color="secondary"
              size="small"
            >
              {translate('tool.btn.more')}
            </Link>
          </Stack>
        ),
      });
    }
  };

  useEffect(() => {
    onCheckActiveExtension();
    onLoadExtensionVersion();
    if (user) {
      onLoadCollections();
    }
  }, [user]);

  const checkCommonData = useCallback(() => {
    if (!userRef?.current) {
      loginWithGoogle();
      return false;
    }

    if (!canUseFeaturesRef?.current) {
      enqueueSnackbar(translate('usage_error'), { variant: 'error' });
      return false;
    }

    if (shouldUpdateExtension) {
      enqueueSnackbar(translate('update_extension'), {
        variant: 'warning',
        autoHideDuration: 5000,
        action: (
          <Link target="_blank" href="https://doc.fbtool.net/" color="secondary" size="small">
            {translate('tool.btn.more')}
          </Link>
        ),
      });
      return false;
    }

    return true;
  }, [user, shouldUpdateExtension]);

  // COLLECTIONS
  const onLoadCollections = async () => {
    try {
      // friends
      const temp2 = await getTargetCollections('tiktok_user');
      setTargetCollections([...temp2]);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        error?.messages || error?.message || error?.details || error?.detail || translate('server_error'),
        {
          variant: 'error',
        }
      );
    }
  };

  const onRemoveTargetCollection = async (id) => {
    if (!checkCommonData()) {
      return;
    }

    try {
      const temp = [...targetCollections];
      const index = temp?.findIndex((item) => item?.id === id);
      if (index !== -1) {
        temp.splice(index, 1);
        setTargetCollections(temp);
      }
      await removeTargetCollection(id);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.messages || error?.message || error?.details || translate('server_error'), {
        variant: 'error',
      });
    }
  };

  const onCreateTargetCollection = async (payload, type = 'tiktok_user') => {
    if (!checkCommonData()) {
      return;
    }

    try {
      const temp = await createTargetCollection(payload, type);
      setTargetCollections((prev) => [...prev, temp]);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.messages || error?.message || error?.details || translate('server_error'), {
        variant: 'error',
      });
    }
  };

  // Manage task running for block redirect
  const updateTaskRunningStatus = (value) => {
    setTaskRunning((prev) => ({ ...prev, [pathname]: value }));
  };

  const resetTaskRunning = () => {
    setTaskRunning({});
  };

  const isTaskRunning = useMemo(() => Object.keys(taskRunning)?.some((path) => taskRunning[path]), [taskRunning]);

  return (
    <CommonToolContext.Provider
      value={useMemo(
        () => ({
          isTaskRunning,
          resetTaskRunning,
          updateTaskRunningStatus,
          // ======
          isOpenPricingModal: promoPopup.value,
          extensionVersion,
          // Version
          tenantExtensionVersion,
          // No sleep
          noSleep,
          closeLoginModal: loginModal.onFalse,
          closePricingModal: promoPopup.onFalse,
          openPricingModal: promoPopup.onTrue,
          openLoginModal: loginWithGoogle,
          checkCommonData,
          // Group collections
          targetCollections,
          onCreateTargetCollection,
          onRemoveTargetCollection,
          onLoadCollections,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
          loginModal,
          extensionModal?.value,
          promoPopup?.value,
          shouldUpdateExtension,
          extensionVersion,
          tenantExtensionVersion,
          warningPopup.value,
          warningActionModal?.value,
          noSleep,
          targetCollections,
        ]
      )}
    >
      {children}
    </CommonToolContext.Provider>
  );
}

export { CommonToolProvider, CommonToolContext };
