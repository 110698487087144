import PropTypes from 'prop-types';
import _ from 'lodash';
import { createContext, useEffect, useMemo, useState } from 'react';
import useRefCustom from '../../../../../../hooks/useRefCustom';
import axiosInstance from '../../../../../../utils/axios';
import { EMAIL_TOOL_API_ENDPOINTS, getContactIdsOfGroup } from '../../../helper';
import { EmitterService } from '../../../../../../utils/event';
import { REFETCH_CONTACT_LIST } from '../../../../../../utils/event/messages';

const initialState = {
  emitter: new EmitterService(),
  // --------------
  groupContacts: [],
  collectionSelected: [],
  onRemoveCollection: () => {},
  getCollectionSelected: () => {},
  onSelectCollection: () => {},
  // --------------
  contactsSelected: [],
  onChangeContacts: () => {},
  // --------------
  onFetchGroupContacts: () => {},
  // --------------
  getContactsSelected: () => {},
};

const GroupContactContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
GroupContactProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function GroupContactProvider({ children }) {
  const [emitter, setEmitter] = useState(new EmitterService());

  const [groupContacts, setGroupContacts] = useRefCustom([]);

  const [collectionSelected, setCollectionSelected, collectionSelectedRef] = useRefCustom([]);

  const [contactsSelected, setContactsSelected, contactsSelectedRef] = useRefCustom([]);

  const [contactGroupFetched, setContactGroupFetched, contactGroupFetchedRef] = useRefCustom({});

  const removeContactsSelected = async (collection) => {
    const ids = await getRecipientIds(collection?.id);
    const filtered = [...contactsSelectedRef?.current]?.filter((item) => ids?.indexOf(item) === -1);
    setContactsSelected(filtered);
  };

  const getRecipientIds = async (contactGroupId) => {
    const current = contactGroupFetchedRef?.current || {};
    let ids = current[contactGroupId] || [];
    if (ids?.length === 0) {
      const res = await getContactIdsOfGroup(contactGroupId);
      ids = res?.ids || [];
    }
    return ids;
  };

  const onRemoveCollection = (collection) => {
    const temp = [...collectionSelected];
    const index = temp?.findIndex((item) => item?.id === collection?.id);
    if (index !== -1) {
      temp.splice(index, 1);
      setCollectionSelected(temp);
      // removeContactsSelected(collection);
    }
    onFetchGroupContacts();
    emitter.send(REFETCH_CONTACT_LIST);
  };

  const onSelectCollection = async (collection) => {
    const temp = [...collectionSelected];
    const index = temp?.findIndex((item) => item?.id === collection?.id);
    if (index !== -1) {
      temp.splice(index, 1);
      // removeContactsSelected(collection);
    } else {
      temp.push(collection);
      // const ids = await getRecipientIds(collection?.id);
      // setContactsSelected(_.uniq([...contactsSelectedRef?.current, ...ids]));
    }
    setCollectionSelected(temp);
  };
  // ==================================================
  const onChangeContacts = (value) => {
    setContactsSelected(value);
  };
  // ================================================

  const onFetchGroupContacts = async () => {
    try {
      const {
        data: { results },
      } = await axiosInstance.get(EMAIL_TOOL_API_ENDPOINTS.group.list);
      setGroupContacts(results || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onFetchGroupContacts();
  }, []);

  const getContactsSelected = () => contactsSelectedRef?.current;
  const getCollectionSelected = () => collectionSelectedRef?.current;

  return (
    <GroupContactContext.Provider
      value={useMemo(
        () => ({
          emitter,
          // --------------
          groupContacts,
          // --------------
          collectionSelected,
          getCollectionSelected,
          onRemoveCollection,
          onSelectCollection,
          // --------------
          contactsSelected,
          onChangeContacts,
          // --------------
          onFetchGroupContacts,
          // --------------
          getContactsSelected,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [collectionSelected, contactsSelected, groupContacts, emitter]
      )}
    >
      {children}
    </GroupContactContext.Provider>
  );
}

export { GroupContactProvider, GroupContactContext };
