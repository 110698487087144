import NoSleep from '@zakj/no-sleep';
import PropTypes from 'prop-types';
import { createContext, useMemo, useRef } from 'react';
import useBoolean from '../../../../../hooks/useBoolean';
import useRefCustom from '../../../../../hooks/useRefCustom';
import useToolFunction from '../../../../../hooks/useToolFunction';
import { randomInRange } from '../../../../../utils/others';

const initialState = {
  isRunning: false,
  postQueues: [],
  onStartJoinGroup: () => {},
  onCancel: () => {},
};

const JoinGroupContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
JoinGroupProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function JoinGroupProvider({ children }) {
  const noSleep = new NoSleep();

  const { joinGroup, stopQueue, restartSignal } = useToolFunction();

  const [postQueues, setPostQueues, postQueuesRef] = useRefCustom([]);

  const [, setGroupIds, groupIdsRef] = useRefCustom([]);

  const [, setSetting, settingRef] = useRefCustom();

  const isRunning = useBoolean();

  const upPostCallback = (payload) => {
    const { success, group, groupId } = payload || {};

    const newArray = [...postQueuesRef?.current] || [];
    const index = newArray.findIndex((item) => item?.uid === groupId);
    if (index === -1) {
      newArray.push({ success, ...group });
      setPostQueues(newArray);
    }
  };

  const onReset = () => {
    isRunning.onFalse();
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }
  };

  const taskRef = useRef();

  const getRemainsGroup = () => {
    const current = [...(groupIdsRef?.current || [])];
    if (current?.length !== 0) {
      const id = current?.shift();
      setGroupIds(current);
      return id;
    }
    return null;
  };

  const loopTask = async (isRestartSignal = false) => {
    const groupId = getRemainsGroup();
    if (!groupId) {
      onReset();
      return;
    }

    const { isOperationCanceled, success, group } = await joinGroup(groupId);

    if (isOperationCanceled) {
      console.log('Break cycle!! STOPPED.');
      onReset();
      return;
    }

    if (group) {
      upPostCallback({ success, group, groupId });
    }

    console.log('Run next cycle!');
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }

    const setting = settingRef?.current || { delay: { from: 30, to: 60 } };
    const { delay } = setting;
    const CYCLE_DELAY = randomInRange(delay) * 1000;
    taskRef.current = setTimeout(() => {
      loopTask();
    }, CYCLE_DELAY);
  };

  const onStartJoinGroup = (data) => {
    try {
      restartSignal();
      isRunning.onTrue();
      const { groupIds, delay } = data;
      setSetting({ delay });
      setGroupIds([...groupIds]);
      loopTask();
    } catch (error) {
      console.log(error);
    }
  };

  const onCancel = () => {
    noSleep?.disable();
    stopQueue(false, 'manual');
    isRunning.onFalse();
    onReset();
  };

  return (
    <JoinGroupContext.Provider
      value={useMemo(
        () => ({
          isRunning: isRunning?.value,
          postQueues,
          onStartJoinGroup,
          onCancel,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRunning?.value, postQueues]
      )}
    >
      {children}
    </JoinGroupContext.Provider>
  );
}

export { JoinGroupProvider, JoinGroupContext };
