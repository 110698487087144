import { Button, Stack } from '@mui/material';
import { useMemo } from 'react';
import ReactGA from 'react-ga4';
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import useCommonToolContext from '../../../hooks/useCommonToolContext';
import { getDaysRemain, modifyTime } from '../../../utils/formatTime';

export default function ExpireTimePopover() {
  const { user } = useAuth();

  const { openLoginModal, openPricingModal } = useCommonToolContext();

  const { translate } = useLocales('tool');

  const haveBuyFbTool = user?.mailtool?.is_bought_mailingtool;

  const dayContent = useMemo(() => {
    if (user?.mailtool?.is_bought_lifetime) {
      return translate('lifetime');
    }
    const days = getDaysRemain(modifyTime(user?.mailtool?.expired_at));
    return days !== '000' ? `${days} days` : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handlePaymentEarly = () => {
    try {
      if (!user) {
        openLoginModal();
        return;
      }
      const ecommerceItems = JSON.parse(localStorage.getItem('ecommerce_items'));
      ReactGA.event('view_item', {
        items: ecommerceItems,
      });
      openPricingModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button
      sx={{
        color: 'primary.main',
        background: '#fff',
        borderRadius: '8px',
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        px: 1.2,
        py: 0.5,
        fontWeight: 700,
        cursor: 'pointer',
        fontSize: '1rem',
        minWidth: 'fit-content',
      }}
      onClick={handlePaymentEarly}
    >
      <Stack direction={'row'} alignItems={'center'} columnGap={0.5}>
        {haveBuyFbTool ? (
          <>
            {' '}
            <Iconify icon={`material-symbols:vpn-key`} sx={{ width: 20, height: 20 }} />
            {dayContent}
          </>
        ) : (
          translate('purchase')
        )}
      </Stack>
    </Button>
  );
}
