/* eslint-disable */

/**
 * Helper class to call api to facebook.
 */
export class Base {
  FACEBOOK_API = 'https://www.facebook.com/api/graphql/';

  FUNCTION_NAME = {
    POST_TO_GROUP: 'POST_TO_GROUP',
    PROFILE: 'PROFILE',
    PROFILE_BY_UID: 'PROFILE_BY_UID',
    SEARCH_TAG: 'SEARCH_TAG',
    TEXT_FORMAT_PRESETS: 'TEXT_FORMAT_PRESETS',
    PARTICIPAGE_APPROVAL: 'PARTICIPAGE_APPROVAL',
    GROUPS_JOINED: 'GROUPS_JOINED',
    FRIENDS: 'FRIENDS',
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    USER_CONTROL_BUSINESS: 'USER_CONTROL_BUSINESS',
    REACTIONS_FROM_FEEDBACK: 'REACTIONS_FROM_FEEDBACK',
    POST_COMMENT: 'POST_COMMENT',
    SITE_INFO: 'SITE_INFO',
    SEARCH_FEEDBACK: 'SEARCH_FEEDBACK',
    SEARCH_POSTS_IN_GROUP: 'SEARCH_POSTS_IN_GROUP',
    SEARCH_POSTS: 'SEARCH_POSTS',
    GET_COMMENTS_FROM_FEEDBACK: 'GET_COMMENTS_FROM_FEEDBACK',
    GET_REPLIES_FROM_COMMENT: 'GET_REPLIES_FROM_COMMENT',
    ADD_FRIEND: 'ADD_FRIEND',
    CHECK_PAGE: 'CHECK_PAGE',
    UPLOAD_VIDEO: 'UPLOAD_VIDEO',
    REMOVE_PENDING_POSTS: 'REMOVE_PENDING_POSTS',
    TIMELINE_POSTS: 'TIMELINE_POSTS',
    TIMELINE_POSTS_OF_PAGE: 'TIMELINE_POSTS_OF_PAGE',
    GET_FOLLOWERS: 'GET_FOLLOWERS',
    POKE: 'POKE',
    LIKE: 'LIKE',
    UNFRIEND: 'UNFRIEND',
    REMOVE_SALE_FORMAT: 'REMOVE_SALE_FORMAT',
    GROUP_ACTIVITY: 'GROUP_ACTIVITY',
    PAGE_LIKED_OR_FOLLOWED: 'PAGE_LIKED_OR_FOLLOWED',
    GET_REACTORS_OF_POST: 'GET_REACTORS_OF_POST',
    GET_RESHARE_OF_POST: 'GET_RESHARE_OF_POST',
    GET_SHARE_LINK_OF_POST: 'GET_SHARE_LINK_OF_POST',
    MARKET_PLACE_lISTING: 'MARKET_PLACE_lISTING',
    MARKET_PLACE_ITEM: 'MARKET_PLACE_ITEM',
    CHECK_GROUP: 'CHECK_GROUP',
    REQUEST_JOIN: 'REQUEST_JOIN',
    GET_QUESTIONS: 'GET_QUESTIONS',
    POSTED_POSTS_OF_USER: 'POSTED_POSTS_OF_USER',
    PENDING_POSTS_OF_USER: 'PENDING_POSTS_OF_USER',
    ANSWER: 'ANSWER',
    FRIENDS_OUTGOING_REQUEST: 'FRIENDS_OUTGOING_REQUEST',
    FRIEND_REQUEST_CANCEL: 'FRIEND_REQUEST_CANCEL',
    UNKNOWN: 'UNKNOWN',
  };

  ACTIONS = {
    POST_TO_GROUP: 'POST_TO_GROUP',
    POST_COMMENT: 'POST_COMMENT',
  };

  PATH_AFTER_UPLOADED = {
    POST_TO_GROUP: ['variables', 'input', 'attachments'],
    POST_COMMENT: ['variables', 'input', 'attachments'],
  };

  ITEM_STRUCTURE = {
    POST_TO_GROUP: {
      photo: {
        id: 'imageId',
      },
    },
    POST_COMMENT: {
      media: {
        id: 'imageId',
      },
    },
  };

  /**
   * constructor of class
   * @param {array} cookies
   * @param {string} fbDtsg
   * @param {string} dyn
   */
  constructor(cookies = [], fbDtsg = '', dyn = '', otherData = {}, accessToken = null, functionData = {}) {
    this.cookies = cookies?.reduce((res, item) => {
      res[item.name] = item?.value;
      return res;
    }, {});

    this.cookies.dbln = `%7B%2261554669931019%22%3A%222ZnumwUX%22%2C%22100031636493707%22%3A%22piBf69wj%22%7D`;
    this.cookiesOrigin = cookies;
    this.fbDtsg = fbDtsg;
    this.dyn = dyn;
    this.otherData = otherData || {};
    this.accessToken = accessToken;
    this.functionData = functionData || {};
  }

  getFilesUploadStructure(files, action = this.ACTIONS.POST_TO_GROUP) {
    try {
      const itemStructure = JSON.stringify(this.ITEM_STRUCTURE[action]);
      return { files, pathAfterUploaded: this.PATH_AFTER_UPLOADED[action], structure: itemStructure };
    } catch (error) {
      return {
        files,
        pathAfterUploaded: this.PATH_AFTER_UPLOADED[this.ACTIONS.POST_TO_GROUP],
        structure: JSON.stringify(this.ITEM_STRUCTURE[this.ACTIONS.POST_TO_GROUP]),
      };
    }
  }

  /**
   * Get cookie string.
   * @return {string}
   */
  getCookieString() {
    return Object.keys(this.cookies)
      ?.map((key) => `${key}=${this.cookies[key]}`)
      .join('; ');
  }

  /**
   * Get headers.
   * @param {object}extraHeaders
   * @return {object}
   */
  getHeaders(extraHeaders = {}) {
    const cookieString = this.getCookieString();
    return {
      accept: `*/*`,
      'accept-language': 'en-US,en;q=0.9',
      'content-type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      cookie: cookieString,
      dpr: 1,
      origin: 'https://www.facebook.com',
      referer: 'https://www.facebook.com',
      'sec-ch-prefers-color-scheme': 'light',
      'sec-ch-ua': `"Google Chrome";v="123", "Not:A-Brand";v="8", "Chromium";v="123"`,
      'sec-ch-ua-full-version-list': `"Google Chrome";v="123.0.6312.106", "Not:A-Brand";v="8.0.0.0", "Chromium";v="123.0.6312.106"`,
      'sec-ch-ua-mobile': '?0',
      'sec-ch-ua-model': '',
      'sec-ch-ua-platform': 'Windows',
      'sec-ch-ua-platform-version': '10.0.0',
      'sec-fetch-dest': 'empty',
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'same-origin',
      'user-agent':
        'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36',
      'viewport-width': 1920,
      ...extraHeaders,
    };
  }

  extractData(funcName) {
    try {
      if (this.FUNCTION_NAME[funcName] && typeof this.functionData[funcName] === 'object') {
        return this.functionData[funcName] || {};
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  /**
   * Get payload.
   * @param {object} extraPayload
   * @return {object}
   */
  getPayload(funcName = this?.FUNCTION_NAME?.UNKNOWN, extraPayload = {}, excludeUnnecessaryData = true) {
    /**
     * Must have: fb_dtsg
     */
    const payload = {
      fb_dtsg: this.fbDtsg,
      server_timestamps: true,
      ...(this.otherData || {}),
      ...extraPayload,
      ...(this.extractData(funcName) || {}),
    };

    const UNNECESSARY_DATA = ['__hs', '__csr'];
    if (excludeUnnecessaryData) {
      return Object.keys(payload).reduce((res, key) => {
        if (UNNECESSARY_DATA?.indexOf(key) !== -1) {
          return res;
        }
        return { ...res, [key]: payload[key] };
      }, {});
    }
    return payload;
  }
}
