const vn = {
  estimated_time: '{{email}} email trong {{time}}',
  btn: {
    save: 'Lưu',
  },
  recipient: {
    list: 'Danh sách Email',
    male: 'Nam',
    female: 'Nữ',
    form: {
      title: 'Nhập dữ liệu',
      name: 'Tên dữ liệu',
      file: {
        heading: 'Chọn 1 tệp CSV',
        title1: 'Gồm các cột last_name (họ), first_name (tên), email, pronoun (danh xưng)',
        example: 'File mẫu',
      },
    },
  },
  accounts: {
    title: 'Tài khoản',
    add: 'Thêm tài khoản Gmail',
  },
  compose: {
    form: {
      subject: 'Tiêu đề',
      normal: 'Bình thường',
      html: 'HTML',
      file: {
        heading: 'Ảnh/Video/File đính kèm',
        title1: 'Kéo thả hoặc chọn tệp',
      },
    },
    table: {
      name: 'Tên',
      sex: 'Giới tính',
      send: 'Đã gửi',
      open: 'Đã mở',
      bounce: 'Từ chối',
      error: 'Lỗi',
    },
    btn: {
      send: 'Gửi',
      stop: 'Dừng',
      ai_spam_check: `AI SpamCheck: Trước {{score}} | Sau {{rewriteScore}}`,
    },
    tooltip: {
      ai_spam_check: `AI kiểm tra nội dung`,
    },
  },
  popup: {
    pricing: {
      title: 'Công cụ hỗ trợ gửi mail trên ',
      sub: 'Gmail',
      heading: 'BẢNG GIÁ',
      features: [
        'Không cần đăng nhập ID và mật khẩu gmail 🔒',
        'Lưu nhóm liên lạc yêu thích',
        'Nhập dữ liệu từ excel',
        'Mua thêm 2 gmail chỉ với 50k/tháng',
      ],
      tier: 'Bậc',
      tiers: [
        {
          tier: 1,
          name: 'BASIC',
          cost: {
            month: 100000,
            year: 50000,
          },
          features: [
            {
              text: 'Thêm không giới hạn email',
              info: 'Thêm không giới hạn email',
            },
            {
              text: 'Gửi không giới hạn email',
              info: 'Gửi không giới hạn email',
            },
            {
              text: 'Dùng với 1 gmail',
              info: 'Dùng với 1 gmail',
            },
          ],
        },
        {
          tier: 2,
          name: 'PLUS',
          cost: {
            month: 200000,
            year: 100000,
          },
          features: [
            {
              text: 'Dùng với 3 gmail',
              info: 'Dùng với 3 gmail',
            },
            {
              text: 'AI Viết lại',
              info: 'AI Viết lại',
            },
            {
              text: 'Cá nhân hóa',
              info: 'Cá nhân hóa nội dung mail',
            },
          ],
        },
        {
          tier: 3,
          name: 'PRO',
          cost: {
            month: 400000,
            year: 200000,
          },
          features: [
            {
              text: 'Lên lịch được',
              info: 'Lên lịch được',
            },
            {
              text: 'Flow automation',
              info: 'Flow automation',
            },
            {
              text: 'Dùng với 10 gmail',
              info: 'Dùng với 10 gmail',
            },
          ],
        },
      ],
      monthly: 'Hàng tháng',
      semi_annual: 'Nửa năm',
      annual: 'Hàng năm',
      month_value: '100K',
      annual_value: '50K',
      discount: 'Tiết kiệm tới {{percent}}%',
      bill_annual: 'Năm',
      bill_monthly: 'Tháng',
    },
  },
  messages: {
    select_recipient: 'Vui lòng chọn ít nhất 1 bộ sưu tập hoặc người liên hệ',
    fill_content: 'Vui lòng điền nội dung gửi',
    limit_sent: `Đạt giới hạn {{amount}}mail/ngày`,
    limit_accounts: `Chọn tối đa {{amount}} tài khoản`,
    select_account: 'Vui lòng chọn ít nhất 1 tài khoản gmail',
    add_account_success: 'Thêm tài khoản gmail thành công',
    add_account_failed: 'Thêm tài khoản gmail thất bại',
    remove_account_success: 'Xóa tài khoản gmail thành công',
    remove_account_failed: 'Xóa tài khoản gmail thất bại',
  },
};

export default vn;
