import { useCallback, useMemo } from 'react';
import useAuth from '../useAuth';
import useFBContext from '../useFBContext';
import useLocales from '../useLocales';
import useToolContext from '../useToolContext';
import useStatsGuard from './useStatsGuard';

export default function useData(isBlockInbox) {
  const { FBUser } = useFBContext();

  const { adCategories } = useAuth();

  const { translate: tLead, mapLocales } = useLocales('tool.lead');

  const { translate: tAdCategories, currentLang } = useLocales('tool.lead.ad_categories');

  const { translate: tMessage } = useLocales('tool.messages');

  const { DAILY_LIMIT_INBOX } = useStatsGuard();

  const isPageProfile = FBUser?.isPageProfile;

  const CATEGORIZE_OPTIONS = [
    {
      label: tLead('categorize.seller'),
      value: 'seller',
    },
    {
      label: tLead('categorize.buyer'),
      value: 'buyer',
    },
    {
      label: tLead('categorize.unknown'),
      value: 'unknown',
    },
  ];

  const ACTION_OPTIONS = [
    {
      label: tLead('action.comment.title'),
      value: 'comment',
      levels: {
        1: {
          options: { keepOriginProduct: false },
          description: tLead('action.comment.level_1'),
        },
        2: {
          options: { keepOriginProduct: true },
          description: tLead('action.comment.level_2'),
        },
      },
      maxLevels: 2,
      // block: true,
    },
    {
      label: tLead('action.like'),
      value: 'like',
    },
    ...(isPageProfile
      ? []
      : [
          {
            label: tLead('action.add_friend'),
            value: 'add_friend',
          },
          {
            label: tLead('action.inbox'),
            value: 'inbox',
            block: isBlockInbox,
            ...(isBlockInbox && { message: tMessage('limit_inbox', { amount: DAILY_LIMIT_INBOX }) }),
          },
        ]),
  ];

  const OPTION_DEFAULT = { categorize: [], actions: [], adCategorize: [], timerange: [] };

  // -------------------------------------------------------
  const SAVED_LINK_CATEGORIZE_OPTIONS = [
    {
      label: tLead('categorize.reactor'),
      value: 'reactor',
    },
    {
      label: tLead('categorize.commenter'),
      value: 'commenter',
    },
    {
      label: tLead('categorize.reshare'),
      value: 'reshare',
    },
  ];

  const SAVED_LINK_ACTION_OPTIONS = [
    {
      label: tLead('action.comment.title'),
      value: 'comment',
      levels: {
        1: {
          options: { keepOriginProduct: false },
          description: tLead('action.comment.level_1'),
        },
        2: {
          options: { keepOriginProduct: true },
          description: tLead('action.comment.level_2'),
        },
      },
      maxLevels: 2,
    },
    {
      label: tLead('action.like'),
      value: 'like',
    },
    ...(isPageProfile
      ? []
      : [
          {
            label: tLead('action.add_friend'),
            value: 'add_friend',
          },
          {
            label: tLead('action.inbox'),
            value: 'inbox',
            block: isBlockInbox,
            ...(isBlockInbox && { message: tMessage('limit_inbox', { amount: DAILY_LIMIT_INBOX }) }),
          },
        ]),
  ];

  // -------------------------------------------------------
  const AD_CATEGORIZE_OPTIONS = useMemo(
    () =>
      adCategories?.map((item) => {
        const raw = tLead(`ad_categories`, { returnObjects: true }) || {};
        const label = raw[item?.name] || mapLocales(item?.meta) || item?.alias;
        return { label, value: item?.name, id: item?.id };
      }),
    [adCategories, currentLang]
  );

  const AD_TIME_RANGE_OPTIONS = useMemo(
    () => [7, 14, 30, 60]?.map((value) => ({ label: tLead('time_range.days_ago', { days: value }), value })),
    [currentLang]
  );

  return {
    ACTION_OPTIONS,
    CATEGORIZE_OPTIONS,
    OPTION_DEFAULT,
    // Saved link or ADS
    SAVED_LINK_ACTION_OPTIONS,
    SAVED_LINK_CATEGORIZE_OPTIONS,
    AD_CATEGORIZE_OPTIONS,
    AD_TIME_RANGE_OPTIONS,
  };
}
