import axios from 'axios';
import _ from 'lodash';
import { CF_WORKER, FBTOOL_GROUP_STATS_SYNC_SIZE } from '../../config';
import { delayFunc, parseJSON } from '../../utils/others';
import useRefCustom from '../useRefCustom';
import useToolFunction from '../useToolFunction';

const DEFAULT_VALUE = {};
const OLD_LOCAL_STORAGE_NAME = ['tool-group-stats', 'tool-group-stats-v2'];
const LOCAL_STORAGE_NAME = 'tool-group-stats-v3';
// Time expire from the first crawl
const EXPIRE_TIME = 7 * 24 * 60 * 60 * 1000;

const FBTOOL_GROUP_STATS_UPDATE_ENDPOINT = `${CF_WORKER}/api/group/stats/update`;

const FBTOOL_GROUP_STATS_GET_ENDPOINT = `${CF_WORKER}/api/group/stats/get`;

// const FBTOOL_GROUP_STATS_COUNT_ENDPOINT = `${CF_WORKER}/api/group/stats/count`;

function useGroupStats() {
  const { getGroupActivity } = useToolFunction();

  const [, setGroupStats, groupStatsRef] = useRefCustom(DEFAULT_VALUE);

  const [, setGroupIdsNeedCrawl, groupIdsNeedCrawlRef] = useRefCustom([]);

  const [, setGroupsJoined, groupsJoinedRef] = useRefCustom([]);

  const findGroupInfo = (groupsJoined, uid) => groupsJoined?.find((item) => item?.uid === uid);

  // Remove old data
  OLD_LOCAL_STORAGE_NAME.forEach((key) => {
    localStorage.removeItem(key);
  });

  const formatServerGroupStats = (data) => {
    return Object.keys(data)?.reduce((res, groupId) => {
      const {
        daily_posts: dailyPosts,
        new_members: newMembers,
        posts_month: postsMonth,
        total_members: totalMembers,
        uid,
        link,
        description,
        image,
        crawled_at: crawledAt,
      } = data?.[groupId] || {};
      return {
        ...res,
        [groupId]: {
          dailyPosts,
          newMembers,
          postsMonth,
          totalMembers,
          uid,
          crawledAt,
          link,
          image,
          description,
        },
      };
    }, {});
  };

  const formatWithCheckExpire = (data) => {
    try {
      const objKeys = Object.keys(data);
      return objKeys?.reduce((res, key) => {
        const item = data[key] || {};
        try {
          item.crawledAt = new Date(item?.crawledAt);
        } catch (error) {
          item.crawledAt = new Date();
        }
        // Skip old data
        if (item?.crawledAt?.getTime() > Date.now() - EXPIRE_TIME) {
          return { ...res, [key]: item };
        }
        return res;
      }, {});
    } catch (error) {
      console.log(error);
      return data;
    }
  };

  const onLoadPreviousData = async (groupsJoined) => {
    try {
      const data = localStorage.getItem(LOCAL_STORAGE_NAME);
      const localStats = parseJSON(data, {});
      // remove key for testing
      delete localStats['504684031881831'];
      let serverStats = {};
      const missingStats = [];
      for (const group of groupsJoined) {
        if (!localStats[group?.uid]) {
          missingStats.push(group?.uid);
        }
      }
      // query for missingStats from CF D1
      const chunks = _.chunk(missingStats, 100);
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        for (const chunk of chunks) {
          const stats = await axios.post(FBTOOL_GROUP_STATS_GET_ENDPOINT, { uid: chunk }, config);
          serverStats = _.merge(serverStats, stats.data);
        }
      }

      serverStats = formatServerGroupStats(serverStats);
      const totalStats = _.merge(localStats, serverStats);

      // Check expire
      const formated = formatWithCheckExpire(totalStats);

      localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(formated));
      setGroupStats(formated);
    } catch (error) {
      console.log(error);
    }
  };

  const setNewStats = (groupId, value) => {
    try {
      if (!value || !groupId) {
        return;
      }
      const current = groupStatsRef?.current || {};

      current[groupId] = { ...value, crawledAt: new Date() };

      setGroupStats({ ...current });
      localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(current));
    } catch (error) {
      console.log(error);
    }
  };

  const loopTasks = async (callback) => {
    try {
      // We currently use ref so it will directly set new value when you pop item from an array
      const groupId = groupIdsNeedCrawlRef?.current?.length !== 0 ? groupIdsNeedCrawlRef?.current?.pop() : null;
      if (!groupId) {
        return;
      }

      const data = await getGroupActivity(groupId);
      if (data?.stop) {
        console.log('[STOP] get group stats');
        return;
      }
      if (data) {
        callback(groupId, data);
        setNewStats(groupId, data);
      }
      await delayFunc(3000);
      await loopTasks(callback);
    } catch (error) {
      console.log(error);
    }
  };

  const asyncGetGroupsData = async (groupsJoined, groupIds, callback) => {
    try {
      setGroupsJoined(groupsJoined || []);
      setGroupIdsNeedCrawl(groupIds);
      await loopTasks(callback);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Splits an array into chunks of the specified size.
   * @param {Array} array - The array to split.
   * @param {number} size - The size of each chunk.
   * @returns {Array} - An array of chunks.
   */
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const saveGroupStats = async (groupsJoined, groupsNeedCrawl) => {
    try {
      // only save newly changed data
      const groupStatsLocal = parseJSON(localStorage.getItem(LOCAL_STORAGE_NAME), {});
      const groupStats = {};
      groupsNeedCrawl.forEach((groupUid) => {
        const data = groupStatsLocal[groupUid];
        if (data) {
          // Append group name
          data.uid = groupUid;
          const groupInfo = findGroupInfo(groupsJoined, groupUid);
          if (groupInfo) {
            data.image = groupInfo?.image;
            data.name = groupInfo?.name;
            data.link = groupInfo?.link;
          }
          groupStats[groupUid] = data;
        }
      });
      // console.log('groupStats', groupStats);
      const chunks = [];
      const keys = Object.keys(groupStats);
      for (let i = 0; i < keys.length; i += FBTOOL_GROUP_STATS_SYNC_SIZE) {
        const chunkKeys = keys.slice(i, i + FBTOOL_GROUP_STATS_SYNC_SIZE);
        const chunk = {};
        chunkKeys.forEach((key) => {
          chunk[key] = groupStats[key];
        });
        chunks.push(chunk);
      }
      for (const chunk of chunks) {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          const config = {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const upsert = await axios.post(FBTOOL_GROUP_STATS_UPDATE_ENDPOINT, chunk, config);
          console.log('upsert status', upsert.status);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGroupStats = () => groupStatsRef?.current;

  return {
    onLoadPreviousData,
    asyncGetGroupsData,
    saveGroupStats,
    getGroupStats,
  };
}

export default useGroupStats;
