import { useContext } from 'react';
import { MailContext } from '../contexts/tool/mail/MailContext';

const useMailToolContext = () => {
  const context = useContext(MailContext);

  if (!context) {
    throw new Error('useMailToolContext context must be use inside MailProvider');
  }

  return context;
};

export default useMailToolContext;
