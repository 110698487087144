// routes
import { PATH_DASHBOARD, PATH_TOOL } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  post: getIcon('ic_post'),
  up_post: getIcon('ic_up_post'),
  lead: getIcon('ic_lead'),
  earn: getIcon('ic_earn'),
  faq: getIcon('ic_faq'),
  usage: getIcon('ic_usage'),
  support: getIcon('ic_support'),
  interactive: getIcon('ic_interactive'),
  join_group: getIcon('ic_group'),
};

const navConfig = (isTenant = false) => {
  const result = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: '',
      items: [
        { title: 'post', path: PATH_TOOL.post.root, icon: ICONS.post },
        { title: 'join_group', path: PATH_TOOL.join_group.root, icon: ICONS.join_group },
        {
          title: 'up_post',
          path: PATH_TOOL.upPost.root,
          icon: ICONS.up_post,
        },
        { title: 'interactive', path: PATH_TOOL.interactive.root, icon: ICONS.interactive },
        { title: 'lead', path: PATH_TOOL.lead.root, icon: ICONS.lead },
      ],
    },
    {
      subheader: '',
      sx: { mt: 'auto !important' },
      items: [
        { title: 'earn', path: PATH_TOOL.footer.earn, icon: ICONS.earn },
        {
          title: 'usage',
          path: PATH_TOOL.footer.usage,
          icon: ICONS.usage,
        },
        { title: 'faqs', path: PATH_TOOL.footer.faqs, icon: ICONS.faq },
        { title: 'support', path: PATH_TOOL.footer.support, icon: ICONS.support },
      ],
    },
  ];

  return result;
};

export default navConfig;
